<template>
  <div class="c-sticky">
    <div class="c-global-search p-inputgroup flex">
      <InputText
        id="purchase-product-search"
        ref="searchInput"
        v-model="query"
        v-debounce:200="search"
        class="w-[25vw] max-w-xs leading-4 bg-[#F0F6FA] text:slate-950 rounded border-none focus:border focus:border-solid hover:border hover:border-solid border-[#F0F6FA] hover:bg-[#F0F6FA] hover:border-[#F0F6FA] active:bg-[#F0F6FA] active:border-[#F0F6FA] focus:bg-[#F0F6FA] focus:border-[#F0F6FA] dark:bg-stone-700 dark:text:stone-200 dark:border-stone-950 dark:hover:bg-stone-600 dark:hover:border-stone-500 dark:active:bg-stone-600 dark:active:border-stone-500 dark:focus:bg-stone-600 dark:focus:border-stone-500 min-w-56 lg:min-w-96"
        aria-controls="product_overlay_panel"
        data-testid="purchase-product-search"
        :placeholder="t('purchase.product.search-placeholder')"
        :disabled="!allowSearch"
        @keydown.enter.prevent="focusSearchResult"
      />
      <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
      <div class="flex flex-wrap 2justify-content-center ml-12">
        <div class="flex items-center">
          <Checkbox
            v-model="onlyInDemand"
            :binary="true"
            input-id="only-in-demand"
            data-testid="only-in-demand"
            @change="onDemandChanged"
            @keydown.tab="tabKeydownInProductDemand"
          />
          <label for="only-in-demand" class="ml-2"> {{ t("purchase.product.only-in-demand") }} </label>
        </div>
      </div>
    </div>
  </div>

  <div class="c-product-search min-w-full">
    <ProductSearchResultList
      ref="productSearchResult"
      v-model:search-products="searchProductList"
      :loading="loadingSearch"
      :currencies="currencies"
      :selected-currency-iso="selectedCurrencyIso"
      :purchase-order-lines="props.purchaseOrderLines"
      :selected-supplier-id="props.selectedSupplierId"
      :show-search-result="query.length > 0"
      @create-new-order-line="createNewOrderLine"
      @show-supplier-dialog="showSupplierDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { useSearch } from "@/api/search/SearchService";
import { ProductOpenQuantity } from "@/models/purchase-order/ProductOpenQuantity";
import { Supplier } from "@/models/supplier/Supplier";
import { usePurchaseShortcuts } from "../utils/PurchaseOrderShortcuts";
import { PurchaseOrderShortcutAction } from "../utils/PurchaseOrderShortcutAction";
import ProductSearchResultList from "./ProductSearchResultList.vue";
import { ProductSearchResponse } from "@/models/search/product/ProductSearchResponse";
import { SearchProduct } from "@/models/search/product/SearchProduct";
import { Currency } from "@/models/currency/Currency";
import { usePurchaseOrderService } from "@/api/purchase-order/PurchaseOrderService";

const props = defineProps<{
  selectedSupplierId: string;
  warehouseId: string | null;
  purchaseOrderLines: PurchaseOrderLine[];
  allowSearch: boolean;
  currencies: Currency[];
  selectedCurrencyIso: string;
}>();

const emit = defineEmits<{
  (e: "createNewOrderLine", value: SearchProductForPurchaseOrder, product: SearchProduct): void;
  (e: "showSupplierDialog", value: SearchProductForPurchaseOrder, product: SearchProduct): void;
  (e: "supplierSelected", value: Supplier): void;
}>();

watch(
  () => props.selectedSupplierId,
  async () => {
    if (selectProductFromSearch !== null) {
      await search();
      productSearchResult.value.selectProduct(selectProductFromSearch);
    }
  },
);

const { productSearch } = useSearch();
const { getOpenQuantitiesForProducts } = usePurchaseOrderService();
const { t } = useI18n();

const loadingSearch = ref(false);
const searchInput = ref();
const searchProductList = ref<SearchProductForPurchaseOrder[]>([]);
const totalHits = ref(0);
const query = ref("");
const onlyInDemand = ref(false);
const productSearchResult = ref();
const searchProducts = ref<SearchProduct[]>([]);

const createNewOrderLine = (product: SearchProductForPurchaseOrder) => {
  const productFromSearch = searchProducts.value.find((x) => x.id === product.id);
  if (productFromSearch) {
    emit("createNewOrderLine", product, productFromSearch);
  }
};

let selectProductFromSearch: SearchProduct | null = null;
const showSupplierDialog = (product: SearchProductForPurchaseOrder) => {
  selectProductFromSearch = searchProducts.value.find((x) => x.id === product.id) ?? null;
  if (selectProductFromSearch) {
    emit("showSupplierDialog", product, selectProductFromSearch);
  }
};

const scrollSearchResultIntoView = () => {
  const el = searchInput.value?.$el as HTMLInputElement;

  if (el == null) {
    return;
  }

  if (window.innerHeight - el.getBoundingClientRect().y < 200) {
    productSearchResult.value.tableProductsSearchResult.value?.$el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};

const search = async () => {
  if (query.value.length < 1) {
    searchProductList.value = [];
    totalHits.value = 0;
    return;
  }

  loadingSearch.value = true;

  scrollSearchResultIntoView();

  try {
    const request = new ProductSearchRequest(query.value);
    request.filters.suppliers = props.selectedSupplierId ? [props.selectedSupplierId] : [];
    request.filters.warehouses = props.warehouseId ? [props.warehouseId] : [];
    request.filters.onlyProductsWithBackOrders = onlyInDemand.value;
    request.includeAvailability = true;

    const productSearchResponse: ProductSearchResponse = await productSearch(request);
    searchProducts.value = productSearchResponse.products;

    const productIds = productSearchResponse.products.map((product) => product.id);

    const productOpenQuantitiesResponse: ProductOpenQuantity[] = await getOpenQuantitiesForProducts(productIds);

    searchProductList.value = SearchProductForPurchaseOrder.createFromProductSearch(
      productSearchResponse,
      productOpenQuantitiesResponse,
      props.warehouseId,
      props.selectedSupplierId,
      props.purchaseOrderLines,
      onlyInDemand.value,
    );

    totalHits.value = productSearchResponse.totalHits;
  } finally {
    loadingSearch.value = false;
  }
};

const selectProductSearchInput = () => {
  searchInput.value.$el.select();
};

usePurchaseShortcuts(PurchaseOrderShortcutAction.SelectProductSearchInput, selectProductSearchInput);

const onDemandChanged = () => {
  search();
};

const elementBtnSave = document.getElementById("btn-save") as HTMLButtonElement;
const elementBtnCancel = document.getElementById("btn-cancel");

const tabKeydownInProductDemand = (event: Event) => {
  if (searchProductList.value.length > 0) {
    focusSearchResult();
    event.preventDefault();
    return;
  } else {
    tabKeydownInSearchList(event);
  }
};

const tabKeydownInSearchList = (event: Event) => {
  elementBtnSave?.disabled ? nextTick(() => elementBtnCancel?.focus()) : nextTick(() => elementBtnSave?.focus());
  event.preventDefault();
};

const focusSearchResult = async () => {
  if (searchProductList.value.length < 1) {
    return;
  }

  const el = productSearchResult.value.tableProductsSearchResult.$el;
  if (el == null) {
    return;
  }

  const firstItem = el.querySelector("tbody tr:first-of-type");
  if (firstItem == null) {
    return;
  }

  firstItem.focus();
  firstItem.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};
const reset = () => {
  query.value = "";
  searchProductList.value = [];
};

defineExpose({
  reset,
  selectProductSearchInput,
});
</script>

<style lang="scss" scoped>
.c-sticky {
  position: sticky;
  top: 15rem;
  bottom: 15rem;
  font-size: 20px;
  padding: 2rem 0.1rem 1rem 0.1rem;
  z-index: 1;

  @media (min-width: 992px) {
    top: 4rem;
    bottom: 6.5rem;
  }
}
</style>
