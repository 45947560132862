<template>
  <FloatLabel variant="on">
    <Select
      id="warehouse"
      v-model="selectedWarehouse"
      data-testId="warehouse"
      :options="dropdownOptions"
      option-label="name"
      option-value="value"
      :disabled="!allowEdit"
      :class="{ 'p-disabled': !allowEdit }"
      :fluid="true"
    />
    <label for="warehouse">{{ t("purchase.warehouse") }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

const { t } = useI18n();
const warehouses = ref<Warehouse[]>([]);
const { getWarehouses } = useWarehouse();

const props = defineProps<{
  warehouseId: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
  purchaseOrderLines: PurchaseOrderLine[];
}>();

const emit = defineEmits<{
  (e: "update:warehouseId", value: string): void;
  (e: "updatePurchaseOrderDelivery", value: Warehouse): void;
}>();

const dropdownOptions = computed(() => {
  return warehouses.value.map((warehouse) => {
    return { name: warehouse.name, value: warehouse.id };
  });
});

const selectedWarehouse = computed<string>({
  get: () => {
    return warehouses.value.find((wh) => wh.id === props.warehouseId)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const warehouse = warehouses.value.find((wh) => wh.id === id);
    if (warehouse && allowEdit.value === true) {
      emit("updatePurchaseOrderDelivery", warehouse);
      emit("update:warehouseId", warehouse.id);
    }
  },
});

const allowEdit = computed<boolean>(() => {
  return (
    (!props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open) &&
    props.purchaseOrderLines.length === 0
  );
});

const fetchWarehouses = async () => {
  warehouses.value = await getWarehouses();
};
onMounted(fetchWarehouses);
</script>
