<template>
  <div
    v-if="editingRowIndex !== index"
    class="c-purchase-orderline-select"
    :data-testid="`purchase-orderline-buttons-${index}`"
  >
    <i
      role="button"
      class="pi pi-ellipsis-h c-context-menu-button"
      :data-testid="`context-menu-button-${index}`"
      @click="emit('openEllipsisContextMenu', $event)"
    >
    </i>
  </div>

  <div v-else class="c-purchase-orderline-edit">
    <Button
      text
      type="button"
      class="c-purchase-orderline-close-button"
      data-testid="purchase-order-line-close"
      :tabindex="editingRowIndex === index ? 0 : -1"
      @click="emit('stopEditRow', -1)"
    >
      <span class="c-row-add material-symbols-outlined material-filled"> </span>
    </Button>
  </div>
</template>
<script setup lang="ts">
const editingRowIndex = defineModel<number | null>("editingRowIndex");
const index = defineModel<number>("index");

const emit = defineEmits<{
  (event: "stopEditRow", rowIndex: number): void;
  (event: "openEllipsisContextMenu", value: Event): void;
}>();
</script>
