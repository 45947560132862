{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før",
    "nonEmptyGuid": "@:validations.required",
    "at-least-one": "Minst en {property} må være lagt til",
    "contact-person-required": "Fornavn, etternavn eller epost er påkrevd"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "print": "Skriv ut",
    "add": "Legg til",
    "new": "Ny innkjøpsordre",
    "save": "Lagre",
    "cancel": "Avbryt",
    "ok": "OK",
    "close": "Lukk",
    "search": "Søk",
    "clear": "Fjern",
    "yes": "Ja",
    "no": "Nei",
    "delete": "Slett",
    "create": "Opprett",
    "use": "Bruk",
    "reload": "Last inn på nytt",
    "reset": "Tilbakestill kolonner",
    "created": "Opprettet",
    "new-line": "Ny linje",
    "error": "Feil",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} innkjøpsordre",
    "loading": "Laster inn, vennligst vent...",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "confirm-delete": "Vil du slette linje no. {posNr}?",
    "confirm": "Bekreft",
    "save-tooltip": "Ingen endringer", 
    "sorting-by": "Sorter etter",
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Ikke funnet",
      "bad-request": "Feil i forespørsel",
      "error-occured": "En feil oppstod"
    },
    "open-received-goods": "Mottatte varer",
    "back": "Tilbake"
  },
  "purchase": {
    "purchase-header": "Innkjøpsordre",
    "pdf": "PDF",
    "printers": "Skrivere",
    "print-success": "Innkjøpsordre sendt til skriver",
    "print-success-detail": "Innkjøpsordre blir skrevet ut til {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix er ikke satt opp for dette firmaet. Gå hit for å sette opp {0}",
    "created": "Opprettet",
    "by": "Av",
    "empty-list": "Bruk søk for å legge til produkter",
    "no-products-found": "Ingen produkter funnet",
    "warehouse": "Lager",
    "no-warehouse": "Ingen lager valgt",
    "currency": "Valuta",
    "incoterms": "Lev. betingelser",
    "purchasing-line": "Innkjøpslinje",
    "add-purchase": "Nytt innkjøp",
    "name": "Navn",
    "open-purchase-orders": "Åpne innkjøpsordre",
    "edit-date-selected-lines": "Endre dato for valgte linjer",
    "modal-column-header":
    {
      "shipping-date": "Forsendelsesdato",
      "eta-date": "ETA dato",
      "confirmed": "Bekreftet"
    },
    "info-tabs": {
      "first-tab": "Leverandør",
      "second-tab": "Leveringsadresse",
      "menu-change-delivery-address": "Endre leveringsadresse"
    },
    "address": {
      "dialog-header": "Endre leveringsadresse",
      "lines": "Addresse",
      "country": "Land",
      "city": "By",
      "postal-code": "Postkode",
      "postal-code-city": "Postkode / by"
    },
    "status": {
      "open": "Åpen",
      "ingoodsreception": "I varemottak",
      "partiallyreceived": "Delvis mottatt",
      "received": "Mottatt"
    },
    "supplier": {
      "search-label": "Leverandør",
      "delivery-to": "Levering til",
      "search-placeholder": "Søk etter leverandører",
      "empty-search-query": "Skriv inn leverandør navn eller nummer for å søke",
      "no-suppliers-found": "'{search}' ikke funnet",
      "search-footer-result": "Viser {searchCount} av {totalSearchCount}",
      "number": "Lev.nummer",
      "name": "Leverandørnavn",
      "address": "Adresse",
      "city": "By",
      "phone": "Telefon{symbol}",
      "email": "Epost{symbol}",
      "select-supplier": "Velg leverandør",
      "no-supplier": "Ingen leverandør valgt"
    },
    "product": {
      "search-placeholder": "Søk etter produkter",
      "number": "Produktnummer",
      "name": "Produktnavn",
      "quantity": "Antall",
      "price-excl-vat": "Pris eks.mva",
      "shipping-date": "Forsendelsesdato",
      "inventory": "På lager",
      "available-stock": "Tilgjengelig lager",
      "order-reserve": "Reservert for ordre",
      "gtin": "GTIN(EAN)",
      "pos-nr": "Posnr",
      "received": "Mottatt",
      "eta-date": "ETA dato",
      "sum-excl-vat": "Sum eks.mva",
      "only-in-demand": "Bare produkter med etterspørsel",
      "supplier-missing-summary": "Leverandør mangler",
      "supplier-missing-detail": "Leverandør er ikke valgt. Vennligst velg en leverandør før du legger til produkter",
      "quantity-back-order": "Antall i restordre",
      "supplier-cost-price": "Leverandørens kostpris",
      "supplier-product-number": "Leverandørens produktnummer",
      "available": "Tilgjengelig",
      "in-demand": "Etterspurt",
      "accessories": "Tilbehør",
      "alternatives": "Alternativ",
      "product": "Produkt",
      "open-product": "Åpne Produkt",
      "group-level": "Gruppe nivå:",
      "dimension-and-weight": "Dimensjoner og vekt:",
      "manufacturer": "Produsent",
      "description": "Beskrivelse",
      "type": "Produkttype",
      "unit": "Unit",
      "quantity-received": "Antall mottatt",
      "dimensions": {
        "label" : "Dimensjoner",
        "height": "Høyde",
        "width": "Bredde",
        "length": "Lengde",
        "weight": "Vekt"
      },
      "info-dialog-header": "Produktinformasjon",
      "confirmed": "Bekreftet",
      "no-order-line-selected":  "Ingen ordrelinje er valgt. Velg en ordrelinje og forsøk igjen."
    },
    "title": {
      "add-purchase": "Legg Til Innkjøp",
      "edit-purchase": "Endre Innkjøp",
      "search-purchase": "Søk Innkjøp",
      "purchase-settings": "Innkjøp Settings",
      "received-goods": "Mottatt Varer"
    },
    "header": {
      "add-purchase": "Legg til innkjøpsordre",
      "edit-purchase": "Oppdater innkjøpsordre"
    },
    "search-header": {
      "add-purchase": "Legg til innkjøp",
      "purchases": "Innkjøp"
    },
    "purchase-reference": {
      "label": "Innkjøps referanse"
    },
    "contact": {
      "label": "Kontaktperson",
      "search-placeholder": "Søk etter kontaktpersoner eller skriv inn navn på en egendefinert kontakt",
      "no-contacts-found": "Ingen kontaktpersoner funnet for søket. Skriv inn navn på en egendefinert kontakt og trykk enter for å legge til.",
      "empty-search-query": "Søk etter kontaktpersoner eller skriv inn navn på en egendefinert kontakt",
      "firstname": "Fornavn",
      "lastname": "Etternavn",
      "phone": "Telefon",
      "email": "E-post",
      "default-contact": "Standard kontakt",
      "email-label": "E-post",
      "is-required": "Navn eller e-post er påkrevd"
    },
    "payment-term": "Betalingsbetingelse",
    "freight-method": "Fraktmåte",
    "shipping-price": "Fraktpris",
    "delivery-option": {
      "label": "Levering",
      "total": "Samlet levering"
    },
    "comment": {
      "label": "Kommentar",
      "placeholder": "Legg inn kommentar..."
    },
    "add": {
      "title": "Opprett innkjøp",
      "toast": {
        "success": {
          "summary": "Lagret",
          "detail": "Innkjøpsordre {purchaseOrderNumber} er nå lagret."
        },
        "validation": {
          "summary": "Validering feilet",
          "detail": "Alle røde felter må rettes"
        }
      }
    },
    "edit": {
      "title": "Innkjøp informasjon",
      "toast": {
        "success": {
          "summary": "Innkjøp er oppdatert",
          "detail": "Endringene er straks klare"
        },
        "validation": {
          "summary": "Validering feilet",
          "detail": "Alle røde felter må rettes"
        }
      }
    },
    "delete": {
      "confirm-message": "Vil du slette innkjøp?",
      "toast": {
        "success": {
          "summary": "Sletter innkjøp",
          "detail": "Innkjøp er snart slettet"
        }
      }
    },
    "purchase-number": {
      "label": "Innkjøp",
      "assigned": "Blir tildelt ved opprettelse"
    },
    "search": {
      "registered": "Registrert",
      "registered-by": "Registrert av",
      "supplier-name": "Leverandørnavn",
      "supplier-number": "Leverandørnummer",
      "pdf": "PDF",
      "purchase-number": "Innkjøpnummer",
      "purchase-reference": "Referanse",
      "purchase-status": "Status",
      "freight-method": "Fraktmetode",
      "quantity-order-lines": "Innkjøpslinjer",
      "total-items-ex-vat": "Total verdi",
      "total-hits": "Ingen innkjøpsordre funnet | 1 innkjøpsordre funnet | {totalHits} innkjøpsordre funnet",
      "currency": "Valuta",
      "contribution-margin": "DG %",
      "toast": {
        "open-purchase-failed": "Kunne ikke åpne innkjøpsordre",
        "no-purchase-selected": "Ingen innkjøpsordre er valgt. Velg en innkjøsordre og prøv på nytt"
      },
      "filter": "Filter",
      "filter-date-from": "Fra{symbol}",
      "filter-date-to": "Til{symbol}",
      "filter-purchase-order-date": "Innkjøpsordre dato",
      "filter-status": "Innkjøpsordrestatus",
      "filter-supplier-number": "Leverandørnummer",
      "filter-product-number": "Produktnummer",
      "filter-registered-by": "Registrert av",
      "filter-last-thirty-days": "Siste 30 dager",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filter": "Tøm filter",
      "latest-purchase-orders": "Siste innkjøpsordre",
      "po-number": "IO nummer",
      "supplier": "Leverandør",
      "quantity": "Antall",
      "cost": "Kost",
      "no-po-found": "Ingen innkjøpsordre funnet for dette produktet",
      "supplier-product-number": "Leverandør's produktnummer",
      "ean": "EAN",
      "list-price": "Listepris",
      "cm": "DG %"
    },
    "footer": {
      "freight": "Frakt(avtalt)",
      "total-items": "Sum eks. MVA",
      "total-sum": "Totalt innkjøpsordre eks. MVA"
    },
    "context-menu": {
      "edit": "Endre",
      "delete": "Slett",
      "product-info": "Produktinformasjon"
    }
  },
  "goods-reception-history": {
    "received-date": "Mottatt dato",
    "initials": "Initialer",
    "quantity-received": "Antall mottatt",
    "batch-number": "Batch nummer",
    "reference": "Dokument referanse",
    "supplier-cost-price": "Leverandørs pris",
    "received-cost": "Mottakspris",
    "goods-reception-number": "Varemottaksnummer"
  },
  "received-goods": {
    "goods-reception-number": "Varemottaksnummer",
    "warehouse-name": "Lager navn",
    "received-date": "Mottatt dato",
    "supplier-number": "Leverandørnummer",
    "supplier-name": "Leverandørnavn",
    "purchase-order-number": "Innkjøpsnummer",
    "document-reference": "Dokument referanse",
    "product-number": "Produktnummer",
    "product-name": "Produktnavn",
    "freight-cost": "Frakt kostnad",
    "currency": "Valuta",
    "received-cost": "Mottakspris",
    "purchase-price": "Innkjøpspris"
  }
}
