<template>
  <PurchaseOrderHeader
    :is-purchase-order-changed="isPurchaseOrderChanged"
    :is-saving="isSaving"
    :allow-edit-purchase-order="allowEditPurchaseOrder"
    :in-goods-reception="editPurchaseOrder.inGoodsReception"
    :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
    :edit-mode="true"
    :loading="loading"
    :title="`${editPurchaseOrder.purchaseOrderNumber} - ${editPurchaseOrder.supplier.name}`"
    @delete-clicked="onDeletePurchaseOrder"
    @on-cancel="purchaseOrderRouteService.routeToPurchaseOrderSearch"
    @save-clicked="onSave"
  />
  <div class="ml-2 my-2" data-testid="edit-purchase">
    <div class="c-content">
      <div class="c-content-top mt-2">
        <div class="c-content-top-left mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-full -mt-2 mb-2">
                  <PurchaseOrderInfo
                    :registered="editPurchaseOrder.registered"
                    :registered-by="editPurchaseOrder.registeredByEmployeeName"
                    :purchase-order-number="editPurchaseOrder.purchaseOrderNumber"
                    :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                  />
                </div>

                <div class="c-col-1 pr-6">
                  <div class="mb-4">
                    <SupplierInput
                      v-model:focus-search-input="focusSupplierSearchInput"
                      :supplier="editPurchaseOrder.supplier"
                      :purchase-order-lines="editPurchaseOrder.purchaseOrderLines"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                      @selected-supplier="selectedSupplier"
                    />
                  </div>

                  <div class="mb-6">
                    <ContactInput
                      :contact="editPurchaseOrder.supplier.contact"
                      :supplier-contacts="supplierContacts"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                      @selected-contact="selectedContact"
                    />
                  </div>

                  <div class="mb-2">
                    <ContactEmailInput
                      v-model:email="editPurchaseOrder.supplier.contact.email"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="-mt-3.5">
                    <InformationTabs
                      v-model:delivery="editPurchaseOrder.delivery"
                      :supplier="editPurchaseOrder.supplier"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 mx-1">
                  <div class="mb-6">
                    <CommentInput
                      v-model:comment="editPurchaseOrder.comment"
                      :in-goods-reception="editPurchaseOrder.inGoodsReception"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>
                  <div class="mt-4 mb-6">
                    <PurchaseOrderReference
                      v-model:purchase-order-reference="editPurchaseOrder.purchaseOrderReference"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-6">
                      <div class="mb-2 mr-6">
                        <FreightMethod
                          v-model:freight-method="editPurchaseOrder.freightMethod"
                          :default-freight-method-id="freightMethodIdFromSupplier"
                          :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-6">
                      <div class="mb-2">
                        <ShippingPrice
                          v-model:shipping-price="editPurchaseOrder.shippingPrice"
                          class="px-6"
                          :currency-iso="editPurchaseOrder.currencyIso"
                          :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-col-2 -mx-4">
                  <div class="mt-1 mb-6 ml-4 mr-4">
                    <PurchaseWarehouse
                      v-model:warehouse-id="editPurchaseOrder.warehouseId"
                      :purchase-order-lines="editPurchaseOrder.purchaseOrderLines"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                      @update-purchase-order-delivery="updatePurchaseOrderDelivery($event)"
                    />
                  </div>

                  <div class="mt-5 ml-4 mr-4 mb-4">
                    <Incoterms
                      v-model:selected-incoterms="editPurchaseOrder.supplier.incoterms"
                      :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1">
                      <div class="mb-2 mt-4 ml-4">
                        <PaymentTerms
                          v-model:payment-term="editPurchaseOrder.paymentTerm"
                          :payment-term-id-from-supplier="paymentTermIdFromSupplier"
                          :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                    <div class="c-col-2">
                      <div class="mb-2 mt-4 ml-6 mr-4">
                        <PurchaseCurrency
                          v-model:currency-iso="editPurchaseOrder.currencyIso"
                          :currencies="currencies"
                          :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div>
        <Card class="c-font-size mx-2">
          <template #content>
            <div class="c-purchase-order-lines-card mb-0 rounded-b-none">
              <PurchaseOrderLines
                ref="purchaseLinesRef"
                v-model:purchase-order-lines="editPurchaseOrder.purchaseOrderLines"
                :focus-first-purchase-order-line="focusFirstPurchaseOrderLine"
                :supplier-id="editPurchaseOrder.supplier.id"
                :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
                :goods-receptions="goodsReceptions"
                :allow-edit="allowEditPurchaseOrder"
                :employee-id="employeeId"
                :currency-iso="editPurchaseOrder.currencyIso"
                :selected-lines="selectedLines"
                :initial-purchase-order-lines="initialPurchaseOrderLines"
                @purchase-order-line-deleted="deletePurchaseOrderLine"
                @purchase-order-price-updated="onPurchaseOrderPriceUpdated"
                @selection-updated="(lines) => (selectedLines = lines)"
              />

              <ProductSearch
                ref="productSearchRef"
                :purchase-order-lines="editPurchaseOrder.purchaseOrderLines"
                :allow-search="allowEditPurchaseOrder"
                :selected-supplier-id="editPurchaseOrder.supplier.id"
                :warehouse-id="editPurchaseOrder.warehouseId"
                :currencies="currencies"
                :selected-currency-iso="editPurchaseOrder.currencyIso"
                @create-new-order-line="createNewOrderLine"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <PurchaseOrderFooter
    v-model:shipping-price="editPurchaseOrder.shippingPrice"
    :sum-total-lines="editPurchaseOrder.sumTotalLines"
    :total-sum="editPurchaseOrder.totalSum"
    :purchase-order-status="editPurchaseOrder.purchaseOrderStatus"
    :currency-iso="editPurchaseOrder.currencyIso"
    :allow-edit="allowEditPurchaseOrder"
    @re-calculate-purchase-order-total="reCalculatePurchaseOrderTotal"
  />
</template>

<script setup lang="ts">
import cloneDeep from "lodash.clonedeep";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut, useUnsavedChanges } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";

//GoodsReception
import { GoodsReception } from "@/models/goods-reception/GoodsReception";
import { useGoodsReception } from "@/api/goods-reception/GoodsReceptionService";

//PurchaseOrder
import { useEditPurchaseOrderStore } from "@/stores/EditPurchaseOrderStore";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import { Address } from "@/models/purchase-order/Address";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { usePurchaseOrderRouteService } from "@/api/purchase-order/PurchaseOrderRouteService";

//Supplier
import { Supplier } from "@/models/supplier/Supplier";
import { SupplierContact } from "@/models/supplier/SupplierContact";
import { useSupplier } from "@/api/supplier/SupplierService";

//Calculations
import { usePurchaseOrderCalculation } from "@/utils/calculation/PurchaseOrderCalculation";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";

import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { Currency } from "@/models/currency/Currency";
import { useCurrencyService } from "@/api/currency/CurrencyService.ts";
import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();

const val = useValidate();
const { t } = useI18n();
const route = useRoute();
const currencies = ref<Currency[]>([]);
const supplierContacts = ref<SupplierContact[]>([]);
const freightMethodIdFromSupplier = ref<string>("");
const paymentTermIdFromSupplier = ref<string>("");
const goodsReceptions = ref<GoodsReception[]>([]);
const purchaseLinesRef = ref();
const productSearchRef = ref();
const focusSupplierSearchInput = ref(true);
const selectedLines = ref<PurchaseOrderLine[]>([]);
const initialPurchaseOrderLines = ref<PurchaseOrderLine[]>([]);
const focusFirstPurchaseOrderLine = ref<boolean>(false);

const { getAllCurrencies } = useCurrencyService();
const { getGoodsReceptionsForPurchaseOrder } = useGoodsReception();
const { getSupplier } = useSupplier();

const editPurchaseOrderStore = useEditPurchaseOrderStore();
const { editPurchaseOrderId, editPurchaseOrder, isPurchaseOrderChanged, isSaving, loading, allowEditPurchaseOrder } =
  storeToRefs(editPurchaseOrderStore);

const purchaseOrderRouteService = usePurchaseOrderRouteService(editPurchaseOrderId);

const { calculateSumLineTotalPrice, calculateSumTotalLines, calculateTotalSum } = usePurchaseOrderCalculation();

const onPurchaseOrderPriceUpdated = (purchaseOrderLine: PurchaseOrderLine, calculationBase: CalculationBase) => {
  const sumLine = calculateSumLineTotalPrice(purchaseOrderLine, calculationBase);
  const line = editPurchaseOrder.value.purchaseOrderLines.find((line) => line.id === purchaseOrderLine.id);
  if (line) {
    line.purchasePrice = purchaseOrderLine.purchasePrice;
    line.sumLine = sumLine;
  }

  editPurchaseOrder.value.sumTotalLines = calculateSumTotalLines(editPurchaseOrder.value.purchaseOrderLines);
  editPurchaseOrder.value.totalSum = calculateTotalSum(
    editPurchaseOrder.value.purchaseOrderLines,
    editPurchaseOrder.value.shippingPrice,
  );
};

const reCalculatePurchaseOrderTotal = () => {
  editPurchaseOrder.value.sumTotalLines = calculateSumTotalLines(editPurchaseOrder.value.purchaseOrderLines);
  editPurchaseOrder.value.totalSum = calculateTotalSum(
    editPurchaseOrder.value.purchaseOrderLines,
    editPurchaseOrder.value.shippingPrice,
  );
};

const updatePurchaseOrderDelivery = (warehouse: Warehouse) => {
  if (warehouse) {
    editPurchaseOrder.value.delivery.name = warehouse.name;
    editPurchaseOrder.value.delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);
  }
};

const toast = useCumulusToast(useToast());

const onSave = async () => {
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }

  val.value.$touch();
  if (!(await val.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("purchase.edit.toast.validation.summary"),
      detail: t("purchase.edit.toast.validation.detail"),
      closable: true,
    });
    return;
  }

  if (await editPurchaseOrderStore.updateOrder()) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
  }
};

const createNewOrderLine = async (product: SearchProductForPurchaseOrder) => {
  const purchaseOrderLine = PurchaseOrderLine.Create(product);
  purchaseOrderLine.quantity = product.quantity ?? 1;
  purchaseOrderLine.purchasePrice = product.supplierPurchasePrice ?? 0;
  purchaseOrderLine.shippingDate = product.shippingDate ?? new Date(Date.now()).toDateOnlyString();
  purchaseOrderLine.estimatedArrivalDate = product.estimatedArrivalDate ?? new Date(Date.now()).toDateOnlyString();
  editPurchaseOrder.value.purchaseOrderLines.push(purchaseOrderLine);
  purchaseOrderLine.positionNumber = editPurchaseOrder.value.purchaseOrderLines.length;

  await reCalculatePurchaseOrderTotal();
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

const deletePurchaseOrderLine = async (purchaseLine: PurchaseOrderLine) => {
  const index = editPurchaseOrder.value.purchaseOrderLines?.findIndex((item) => item.id === purchaseLine.id);
  if (index !== -1) {
    editPurchaseOrder.value.purchaseOrderLines?.splice(index, 1);
    for (let index = 0; index < editPurchaseOrder.value.purchaseOrderLines.length; index++) {
      editPurchaseOrder.value.purchaseOrderLines[index].positionNumber = index + 1;
    }

    await reCalculatePurchaseOrderTotal();
  }
};

const fetchGoodsReceptionsForPurchaseOrder = async () => {
  const purchaseOrderId = route.params.id as string;
  goodsReceptions.value = await getGoodsReceptionsForPurchaseOrder(purchaseOrderId);
};

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const employeeId = ref<string>("");
onMounted(async () => {
  await editPurchaseOrderStore.clearStore();

  employeeId.value = (await getUser()).getEmployee().id;
  if ((await editPurchaseOrderStore.getOrder(route.params.id as string)) === null) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
    return;
  }

  const supplier = await getSupplier(editPurchaseOrder.value.supplier.id);
  supplierContacts.value = supplier.contacts;

  await fetchGoodsReceptionsForPurchaseOrder();
  await fetchCurrencies();

  await reCalculatePurchaseOrderTotal();
  focusFirstPurchaseOrderLine.value = true;

  initialPurchaseOrderLines.value = cloneDeep(editPurchaseOrder.value.purchaseOrderLines);
});

const onDeletePurchaseOrder = async () => {
  if (await editPurchaseOrderStore.deleteOrder(editPurchaseOrder.value.id)) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
  }
};

useShortcut(ShortcutAction.save, onSave);

const selectedSupplier = (supplier: Supplier) => {
  editPurchaseOrder.value.supplier = PurchaseOrderSupplier.createFromSupplier(supplier);
  supplierContacts.value = supplier.contacts;
  editPurchaseOrder.value.currencyIso = supplier.currency?.name ?? "";

  paymentTermIdFromSupplier.value = supplier.defaultPaymentTermId;
  freightMethodIdFromSupplier.value = supplier.freightMethodId;
};

const selectedContact = (contact: SupplierContact) => {
  editPurchaseOrder.value.supplier.contact = contact;
};

useUnsavedChanges(isPurchaseOrderChanged);
</script>

<style lang="scss" scoped>
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-full {
  flex: 100%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}
</style>
