<template>
  <div class="c-purchase-order-lines min-w-full">
    <div class="card">
      <ColumnOptionsMenu :label="t('purchase.search.quantity-order-lines')" :itemsList="items"></ColumnOptionsMenu>

      <DataTable
        dataKey="id"
        ref="tablePurchaseOrderLines"
        :value="purchaseOrderLinesComputed"
        v-model:expandedRows="expandedRows"
        responsiveLayout="scroll"
        selectionMode="multiple"
        v-model:selection="selectedRows"
        @update:selection="onSelectionUpdated"
        id="purchase-order-lines"
        class="c-datatable c-purchase-order-table"
        :class="{
          hidden: purchaseOrderLinesComputed.length < 1,
        }"
        striped-rows
        @row-select="onRowSelected"
        @rowUnselect="onRowUnselect"
        @row-dblclick="onSelectOrderLine"
        :rowClass="addRowClass"
        data-testid="purchase-order-lines"
        @keydown="handleKeyPress($event)"
        @rowContextmenu="onRowContextMenu"
        :resizableColumns="true"
        columnResizeMode="fit"
        :reorderable-columns="true"
        @column-reorder="onColumnReorder"
        @column-resize-end="onColumnResizeEnd"
        :pt="{
          bodyRow: () => ({
            class: 'no-click',
          }),
        }"
        :key="renderKey as unknown as number"
      >
        <Column
          v-for="col of selectedColumnsComputed as unknown as DataTableColumnExt[]"
          :field="col.field"
          :header="col.header"
          :key="col.field"
          :columnKey="col.field"
          :class="col.class"
          :sortable="col.sortable"
          :expander="col.expander"
          :pt="{
            headerCell: {
              id: col.field,
              tabindex: -1,
            },
            columntitle: {
              class: {
                hidden: col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines,
              },
            },
            bodyCell: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                clickable: true ? rowData.openQuantity > 0 : false,
              },
            }),
            rowToggleButton: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                hidden: rowData.receivedQuantity === 0,
                clickable: true,
              },
            }),
          }"
          :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
        >
          <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines" #header>
            <Checkbox
              v-model="checkedAllRows"
              :binary="true"
              @click.stop
              data-testid="purchase-order-line-all-checkbox"
            />
          </template>
          <template v-if="col.field != PurchaseOrderLineColumns.ExpanderHandle" #body="{ data, field, index }">
            <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines">
              <Checkbox
                v-model="selectedRows"
                :value="data"
                data-testid="purchase-order-line-checkbox"
                @click.stop
                :class="{ hidden: data.openQuantity <= 0 }"
              />
            </template>
            <template v-if="col.field === PurchaseOrderLineColumns.PositionNumber">
              <span :data-testid="`purchase-order-line-position-number-${index}`">{{ data[field] }}</span>
            </template>

            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductNumber">
              <span :data-testid="`purchase-order-line-product-number-${index}`">{{ data.product.productNumber }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.SupplierProductNumber">
              <span :data-testid="`purchase-order-line-supplier-product-number-${index}`">{{
                data.product.supplierProductNumber
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Gtin">
              <span :data-testid="`purchase-order-line-gtin-${index}`">{{ data.product.gtin }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductName">
              <span :data-testid="`purchase-order-line-product-name-${index}`">{{ data.product.name }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Quantity">
              <PurchaseOrderLineInput
                v-if="editingRowIndex === index"
                inputClass="w-20"
                :id="`purchase-order-line-quantity-${index}`"
                :value="data[field]"
                :minValue="minQuantity(data)"
                :allowEmpty="false"
                @update:modelValue="updateQuantity($event, data)"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                @keydown.enter.prevent="stopEditRow(index)"
                @keydown.esc.prevent="stopEditRow(index)"
                :setFocus="true"
                data-testid="purchase-order-line-quantity"
              />

              <span :data-testid="`purchase-order-line-quantity-${index}`" v-if="editingRowIndex !== index" data>{{
                data[field]
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ReceivedQuantity">
              <span :data-testid="`purchase-order-line-received-${index}`">{{ data[field] }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.PurchasePrice">
              <PurchaseOrderLineInput
                v-if="editingRowIndex === index"
                :id="`purchase-order-line-supplier-cost-price-${index}`"
                data-testid="purchase-order-line-supplier-cost-price"
                :value="data[field]"
                @onUpdateValue="onUpdatePurchasePrice($event, data)"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                inputClass="w-32"
                :min="0"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                @keydown.enter.prevent="stopEditRow(index)"
                @keydown.esc.prevent="stopEditRow(index)"
              />

              <span
                :data-testid="`purchase-order-line-supplier-cost-price-${index}`"
                v-if="editingRowIndex !== index"
                data
                >{{ n(data[field], "decimal") }}</span
              >
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ShippingDate">
              <PurchaseDate
                v-if="editingRowIndex === index"
                :id="`shipping-id-${index}`"
                v-model:dateTime="data[field]"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                dataTestId="purchase-order-line-shipping-date"
                @update:dateTime="updateShippingDate($event, data)"
                @keydown.esc.prevent="stopEditRow(index)"
              />
              <span :data-testid="`purchase-order-line-shipping-date-${index}`" v-if="editingRowIndex !== index" data>{{
                data[field]
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EstimatedArrivalDate">
              <PurchaseDate
                v-if="editingRowIndex === index"
                :id="`arrival-date-${index}`"
                v-model:dateTime="data[field]"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                dataTestId="purchase-order-line-estimated-arrival-date"
                @update:dateTime="updateEstimatedArrivalDate($event, data)"
                @keydown.esc.prevent="stopEditRow(index)"
              />
              <span
                :data-testid="`purchase-order-line-estimated-arrival-date-${index}`"
                v-if="editingRowIndex !== index"
                data
                >{{ data[field] }}</span
              >
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Confirmed">
              <span :data-testid="`purchase-order-line-confirmed-${index}`"
                ><Checkbox
                  :binary="true"
                  :modelValue="data.confirmed"
                  @update:modelValue="updateConfirmed($event, data)"
                  :disabled="!allowEdit || lineIsFullyReceived(data)"
                ></Checkbox
              ></span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.SumLine">
              <span :data-testid="`purchase-order-line-sum-${index}`">{{
                n(data[field], "currency", currencyIso)
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EditHandle">
              <EditHandle
                :index="index"
                :editingRowIndex="editingRowIndex"
                @openEllipsisContextMenu="(e) => openEllipsisContextMenu(e, data, index)"
                @stopEditRow="stopEditRow"
              ></EditHandle>
            </template>
            <template v-else>
              {{ data[field] }}
            </template>
          </template>
        </Column>

        <template #expansion="slotProps">
          <GoodsReceptionHistory
            :goodsReceptions="getGoodsReceptionsForPurchaseOrderLineId(slotProps.data.id)"
            :purchaseOrderLineId="slotProps.data.id"
          />
        </template>
        <template #empty>
          <div class="c-table-empty">{{ t("purchase.empty-list") }}</div>
        </template>
      </DataTable>

      <RowContextMenu
        :disableDeleteOption="disableDeleteOption"
        ref="ellipsisContextMenuRef"
        @openProductInfo="openProductInfoDialog(selectedRow.product.id)"
        @selectRow="selectRow(selectedRowIndex)"
        @deletePurchaseLine="(e) => onConfirmDelete(e.originalEvent, selectedRow)"
        data-testId="row-context-menu"
      />
    </div>
    <ColumnChooser
      v-model:visibleDialog="visible"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      :onSelectAllChange="onSelectAllChange"
      :selectAll="selectAll"
      @resetColumns="resetColumns"
      @columnSelected="onColumnSelected"
      @columnUnselected="onColumnUnselected"
    />

    <ProductInfoDialog v-if="showDialog" v-model:showDialog="showDialog" :productId="selectedRow.product.id" />
    <PrimeDialog v-model:visible="selectedLinesDialogVisible" :modal="true" :class="'w-1/5'" :closable="false">
      <template #header>
        <div>{{ t("purchase.edit-date-selected-lines") }}</div>
      </template>
      <SelectedPurchaseOrderLinesDialog
        :selectedLines="props.selectedLines"
        @purchaseOrderSelectedLines="
          (confirmed: boolean, shippingDate: string, estimatedArrivalDate: string) =>
            purchaseOrderSelectedLines(confirmed, shippingDate, estimatedArrivalDate)
        "
        @cancel="selectedLinesDialogVisible = false"
      />
    </PrimeDialog>
    <small class="p-error" v-if="val.purchaseOrderLines.$error" data-testid="purchase-order-line-error">
      {{ val.purchaseOrderLines.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import GoodsReceptionHistory from "./GoodsReceptionHistory.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import PurchaseOrderLineInput from "./PurchaseOrderLineInput.vue";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";
import cloneDeep from "lodash.clonedeep";
import { GoodsReception } from "@/models/goods-reception/GoodsReception";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import {
  DataTableRowContextMenuEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableRowUnselectEvent,
  DataTableSelectAllChangeEvent,
} from "primevue/datatable";
import { useConfirm } from "primevue/useconfirm";
import { PurchaseOrderLineColumns } from "@/models/purchase-order/PurchaseOrderLineColumns";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumnExt } from "@cumulus/components";
import RowContextMenu from "./RowContextMenu.vue";
import ProductInfoDialog from "./product-info/ProductInfoDialog.vue";
import { useAuth } from "@cumulus/event-bus";
import SelectedPurchaseOrderLinesDialog from "./SelectedPurchaseOrderLinesDialog.vue";
import EditHandle from "./EditHandle.vue";
import { usePurchaseOrderLinesFunctions } from "@/utils/PurchaseOrderLinesFunctions";
import PurchaseDate from "./PurchaseDate.vue";

const visible = ref(false);
const confirm = useConfirm();
const { t, n } = useI18n();
const selectedLinesDialogVisible = ref(false);

const props = defineProps<{
  purchaseOrderLines: PurchaseOrderLine[];
  goodsReceptions: GoodsReception[];
  allowEdit: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  currencyIso: string;
  employeeId: string;
  selectedLines: PurchaseOrderLine[];
  initialPurchaseOrderLines: PurchaseOrderLine[];
}>();

const purchaseLineColumns = [
  {
    field: "selectPurchaseOrderLines",
    header: "Checkbox",
    class: "w-12",
    sortable: false,
  },
  {
    field: "expanderHandle",
    header: "",
    sortable: false,
    class: "c-col-expanderHandle w-12",
    expander: true,
  },
  { field: "positionNumber", header: t("purchase.product.pos-nr"), sortable: true },
  { field: "product.productNumber", header: t("purchase.product.number"), sortable: true },
  {
    field: "product.supplierProductNumber",
    header: t("purchase.product.supplier-product-number"),
    sortable: true,
  },
  { field: "product.gtin", header: t("purchase.product.gtin"), sortable: true },
  { field: "product.name", header: t("purchase.product.name"), sortable: true },
  {
    field: "quantity",
    header: t("purchase.product.quantity"),
    sortable: true,
    class: "c-col-quantity py-0",
  },
  {
    field: "receivedQuantity",
    header: t("purchase.product.quantity-received"),
    sortable: true,
    class: "py-0",
  },
  {
    field: "purchasePrice",
    header: t("purchase.product.supplier-cost-price"),
    sortable: true,
  },
  {
    field: "shippingDate",
    header: t("purchase.product.shipping-date"),
    sortable: true,
    class: "max-w-40 py-0",
  },
  {
    field: "estimatedArrivalDate",
    header: t("purchase.product.eta-date"),
    sortable: true,
    class: "max-w-40 py-0",
  },
  { field: "confirmed", header: t("purchase.product.confirmed"), sortable: false },
  { field: "sumLine", header: t("purchase.product.sum-excl-vat"), sortable: true, class: "text-right" },
  { field: "editHandle", header: "", sortable: false, class: "c-col-actions py-0 w-4 p-4" },
];

const { getUser } = useAuth();

const {
  selectedColumnsComputed,
  orderedColumns,
  renderKey,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
  onColumnReorder,
  resetColumns,
} = useTablePreferences("purchaseOrderLines", purchaseLineColumns, null, (await getUser()).getEmployee().id);

const items = ref([
  {
    label: "Column chooser",
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
]);

const filteredColumns = computed(() => {
  if (props.purchaseOrderStatus && props.purchaseOrderStatus !== PurchaseOrderStatus.Open) {
    return orderedColumns.value.filter(
      (col) =>
        col.field !== PurchaseOrderLineColumns.PositionNumber &&
        col.field !== PurchaseOrderLineColumns.SupplierProductNumber &&
        col.field !== PurchaseOrderLineColumns.ProductNumber
    );
  } else {
    return orderedColumns.value.filter(
      (col) =>
        col.field !== PurchaseOrderLineColumns.ExpanderHandle &&
        col.field !== PurchaseOrderLineColumns.EditHandle &&
        col.field !== PurchaseOrderLineColumns.PositionNumber &&
        col.field !== PurchaseOrderLineColumns.SupplierProductNumber &&
        col.field !== PurchaseOrderLineColumns.ProductNumber
    );
  }
});

const editingRowIndex = ref<number | null>(null);

const showDialog = ref<boolean>(false);
const previouslyFocusedRow = ref();
const ellipsisContextMenuRef = ref();
const selectedRow = ref<PurchaseOrderLine>(new PurchaseOrderLine());
const selectedRowIndex = ref<number>(0);

const addRowClass = (data: PurchaseOrderLine) => {
  if (lineIsFullyReceived(data)) return "c-line-done";
  return [`c-search-result-row c-sr-id-${data.id}`];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (editingRowIndex.value === event.index) return;

  editingRowIndex.value = null;
  if (event.originalEvent != null) {
    if (!(event.originalEvent instanceof KeyboardEvent)) {
      return;
    }
    if (event.originalEvent.key === "Enter" && event.data.openQuantity != 0) {
      selectRow(event.index);
    }
  }
};

const onRowUnselect = (event: DataTableRowUnselectEvent) => {
  if (event.originalEvent != null) {
    if (event.originalEvent instanceof KeyboardEvent && event.originalEvent.key === "Enter") {
      if (props.selectedLines.length > 1) {
        selectedLinesDialogVisible.value = true;
      } else {
        editingRowIndex.value = event.index;
      }
    }
  }
};

const purchaseOrderSelectedLines = (confirmed: boolean, shippingDate: string, estimatedArrivalDate: string) => {
  props.selectedLines.forEach((line) => {
    line.confirmed = confirmed;
    if (shippingDate !== "") line.shippingDate = shippingDate.split("T")[0];
    if (estimatedArrivalDate !== "") line.estimatedArrivalDate = estimatedArrivalDate.split("T")[0];
  });

  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  selectedLinesDialogVisible.value = false;
};

const selectedRows = ref<PurchaseOrderLine[]>([]);

watch(selectedRows, (newSelectedRows) => {
  if (newSelectedRows.length > 1) {
    if (editingRowIndex.value !== null) {
      stopEditRow(editingRowIndex.value);
    }
  }
  emit("selectionUpdated", newSelectedRows);
});

const onSelectionUpdated = () => {
  selectedRows.value = selectedRows.value.filter((row) => row.openQuantity > 0);
  if (selectedRows.value.length === 0) {
    const focusedElement = document.activeElement;
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          selectRow(index);
        }
      });
    }
  }
  emit("selectionUpdated", selectedRows.value);
};

const checkedAllRows = computed<boolean>({
  get: () => selectedRows.value.length === props.purchaseOrderLines.filter((row) => row.openQuantity > 0).length,
  set: (value) => {
    if (!value) {
      selectedRows.value = [];
    } else {
      selectedRows.value = props.purchaseOrderLines.filter((row) => row.openQuantity > 0);
      selectedRows.value.forEach((_, index) => {
        stopEditRow(index);
      });
    }
  },
});

const onSelectOrderLine = (event: DataTableRowDoubleClickEvent) => {
  if (tablePurchaseOrderLines.value && event.index > -1) {
    const row = tablePurchaseOrderLines.value.$el.querySelectorAll("tbody tr")[event.index];

    const inputElement = row?.querySelector("input");
    if (inputElement && inputElement.type !== "checkbox") {
      stopEditRow(event.index);
    } else {
      selectRow(event.index);
    }
  }
};

const selectRow = (index: number) => {
  props.selectedLines.length > 1 ? (selectedLinesDialogVisible.value = true) : (editingRowIndex.value = index);
};

const stopEditRow = (index: number) => {
  editingRowIndex.value = null;

  const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
  if (tablePurchaseOrderLines.value && index > -1) {
    const row = rows[index];
    row.focus();
    document.addEventListener(
      "keydown",
      (event) => {
        if (event.key === "Enter") {
          const nextRow = rows[index + 1];
          if (nextRow) {
            nextRow.focus();
            selectedRowIndex.value = index + 1;
            openFieldsOnRow(index + 1);
          }
        }
      },
      { once: true }
    );
  }
};

const openFieldsOnRow = (index: number) => {
  previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
  editingRowIndex.value = index;
};

const getGoodsReceptionsForPurchaseOrderLineId = (purchaseOrderId: string) =>
  props.goodsReceptions.filter((x: GoodsReception) =>
    x.goodsReceptionLines.some((y) => y.purchaseOrderLineId === purchaseOrderId)
  );

const emit = defineEmits<{
  (e: "purchaseOrderLineDeleted", value: PurchaseOrderLine): void;
  (e: "update:purchaseOrderLines", value: PurchaseOrderLine[]): void;
  (e: "selectionUpdated", lines: PurchaseOrderLine[]): void;
  (e: "purchaseOrderPriceUpdated", purchaseOrderLine: PurchaseOrderLine, value: CalculationBase): void;
}>();

const expandedRows = ref([]);

const purchaseOrderLinesComputed = computed<PurchaseOrderLine[]>(() => {
  // Cloning the object here to avoid mutation on nested object properties causing re-renders
  // https://vuejs.org/guide/components/props.html#one-way-data-flow
  return cloneDeep(props.purchaseOrderLines);
});

const minQuantity = (purchaseOrderLine: PurchaseOrderLine) => {
  if (purchaseOrderLine.receivedQuantity > 0) {
    return purchaseOrderLine.receivedQuantity;
  }
  return 1;
};

const updateQuantity = async (value: number, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.quantity = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  emit("purchaseOrderPriceUpdated", purchaseOrderLine, CalculationBase.PurchasePrice);
};

const deletePurchaseOrderLine = (purchaseOrderLine: PurchaseOrderLine) => {
  emit("purchaseOrderLineDeleted", purchaseOrderLine);
};

const updateShippingDate = (value: string, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.shippingDate = value.toString().split("T")[0];
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const onUpdatePurchasePrice = (value: number, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.purchasePrice = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  emit("purchaseOrderPriceUpdated", purchaseOrderLine, CalculationBase.PurchasePrice);
};

const updateEstimatedArrivalDate = (value: string, purchaseLine: PurchaseOrderLine) => {
  purchaseLine.estimatedArrivalDate = value.toString().split("T")[0];
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const updateConfirmed = (value: boolean, purchaseLine: PurchaseOrderLine) => {
  purchaseLine.confirmed = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const rules = {
  purchaseOrderLines: {
    required: helpers.withMessage(
      t("validations.at-least-one", { property: t("purchase.purchasing-line").toLowerCase() }),
      required
    ),
    minLength: minLength(1),
  },
};

const val = useVuelidate(rules, props);

const onConfirmDelete = (event: Event, purchaseOrderLine: PurchaseOrderLine) => {
  const targetElement = event.target as HTMLElement;

  confirm.require({
    message: t("common.delete-confirm", { posNr: purchaseOrderLine.positionNumber }),
    header: t("common.confirm"),
    icon: "pi pi-exclamation-triangle",
    position: "center",
    rejectProps: {
      label: t("common.no"),
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: t("common.yes"),
    },
    accept: async () => {
      deletePurchaseOrderLine(purchaseOrderLine);
    },
    reject: async () => {
      if (document.body.contains(targetElement)) {
        targetElement.focus();
      } else if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    },
  });
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? purchaseLineColumns
    : purchaseLineColumns.filter(
        (c) =>
          c.field === PurchaseOrderLineColumns.PositionNumber ||
          c.field === PurchaseOrderLineColumns.SupplierProductNumber ||
          c.field === PurchaseOrderLineColumns.ProductNumber ||
          c.field === PurchaseOrderLineColumns.EditHandle ||
          c.field === PurchaseOrderLineColumns.ExpanderHandle
      );
};

const openProductInfoDialog = (productId: string) => {
  selectedRow.value.product.id = productId;
  showDialog.value = true;
};
const resetRowValues = (index: number) => {
  const initialValues = props.initialPurchaseOrderLines[index];
  const currentValues = props.purchaseOrderLines[index];

  if (currentValues != initialValues) {
    Object.assign(currentValues, initialValues);
  }
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const { handleKeyPress, tablePurchaseOrderLines } = usePurchaseOrderLinesFunctions(
  selectedRowIndex,
  resetRowValues,
  setFocusedRow,
  openProductInfoDialog,
  onConfirmDelete,
  purchaseOrderLinesComputed,
  props.purchaseOrderStatus
);

const disableDeleteOption = ref(false);

const deleteOptionsRules = (data: PurchaseOrderLine): boolean => {
  if (props.allowEdit && data.status === PurchaseOrderStatus.Open) {
    return false;
  }

  if (data.status === PurchaseOrderStatus.Received) {
    return true;
  }

  if (data.status === PurchaseOrderStatus.PartiallyReceived && data.receivedQuantity === 0) {
    return false;
  }
  return true;
};

const openEllipsisContextMenu = (event: Event, data: PurchaseOrderLine, index: number) => {
  selectedRow.value = data;
  selectedRowIndex.value = index;
  setFocusedRow(index);
  disableDeleteOption.value = deleteOptionsRules(data);
  ellipsisContextMenuRef.value.openMenu(event);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  selectedRow.value = event.data;
  selectedRowIndex.value = event.index;
  setFocusedRow(event.index);
  disableDeleteOption.value = deleteOptionsRules(event.data);
  ellipsisContextMenuRef.value.openMenu(event.originalEvent);
};

const lineIsFullyReceived = (line: PurchaseOrderLine) => {
  return line.quantity - line.receivedQuantity === 0;
};

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value === false) {
      if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    }
  }
);
</script>

<style lang="scss" scoped>
:deep(.no-click) {
  pointer-events: none;
}

:deep(.c-line-done) {
  opacity: 0.6;
}

:deep(.no-click .clickable) {
  pointer-events: auto;
}

.c-col-expanderHandle {
  width: 3rem;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 22;
}

:deep(.p-inputnumber-input) {
  text-align: right;
}

:deep(.p-inputnumber.p-component.p-inputwrapper) {
  width: 100%;
}

:deep(.c-row-input.p-inputnumber-input) {
  --border-color: var(--select-border);
  font-size: 0.9rem;
  padding: 0.5rem;
  height: 1.8rem;
}

:deep(.p-datatable-header) {
  background: transparent;
  border: none;
  padding: 0;
}

:deep(.p-datatable-tbody > tr.p-datatable-row-selected) {
  background: var(--p-datatable-row-focus-ring-color);
  color: var(--p-surface-950);
}

:deep(.c-purchase-order-table).p-datatable .p-datatable-tbody > tr {
  .c-purchase-orderline-select {
    display: none;
    color: var(--action-btn-bg);
    text-align: center;
  }

  .c-purchase-orderline-edit {
    display: block;
    color: var(--action-btn-bg);
    text-align: center;
  }

  .c-col-actions {
    padding: 0;
  }

  &:hover .c-purchase-orderline-select,
  &:focus .c-purchase-orderline-select {
    display: block;
    .p-button-icon {
      color: var(--action-btn-bg);
    }
    .c-delete-button .p-button-icon {
      color: var(--delete-btn-bg);
    }
  }
}

.c-purchase-orderline-close-button {
  padding: 0;
  color: var(--action-btn-bg);
}
:deep(.p-checkbox-checked .p-checkbox-box:hover) {
  background-color: var(--p-button-success-hover-background);
  border-color: var(--p-button-success-hover-background);
}

:deep(.p-checkbox-checked .p-checkbox-box) {
  background-color: var(--p-button-success-background);
  border-color: var(--p-button-success-background);
}
</style>
