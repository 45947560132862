<template>
  <div class="c-purchase-order-lines min-w-full">
    <PurchaseOrderLinesToolbar
      :label="t('purchase.search.quantity-order-lines')"
      :is-chooser-visible="isChooserVisible"
      @open-column-chooser="openColumnChooser"
    />
    <div class="card">
      <DataTable
        id="purchase-order-lines"
        ref="tablePurchaseOrderLines"
        :key="renderKey as unknown as number"
        v-model:expanded-rows="expandedRows"
        v-model:selection="selectedRows"
        data-key="id"
        :value="purchaseOrderLinesComputed"
        responsive-layout="scroll"
        selection-mode="multiple"
        class="c-datatable c-purchase-order-table"
        :class="{
          hidden: purchaseOrderLinesComputed.length < 1,
        }"
        striped-rows
        :row-class="addRowClass"
        data-testid="purchase-order-lines"
        :resizable-columns="true"
        column-resize-mode="fit"
        :reorderable-columns="true"
        :pt="{
          bodyRow: () => ({
            class: 'no-click',
          }),
        }"
        @update:selection="onSelectionUpdated"
        @row-select="onRowSelected"
        @row-unselect="onRowUnselect"
        @row-dblclick="onSelectOrderLine"
        @keydown="handleKeyPress($event)"
        @row-contextmenu="onRowContextMenu"
        @column-reorder="onColumnReorder"
        @column-resize-end="onColumnResizeEnd"
      >
        <Column
          v-for="col of selectedColumnsComputed as unknown as DataTableColumnExt[]"
          :key="col.field"
          :field="col.field"
          :header="t(col.header)"
          :column-key="col.field"
          :class="col.class"
          :sortable="col.sortable"
          :expander="col.expander"
          :pt="{
            headerCell: {
              id: col.field,
              tabindex: -1,
            },
            columntitle: {
              class: {
                hidden: col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines,
              },
            },
            bodyCell: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                clickable: true ? rowData.openQuantity > 0 : false,
              },
            }),
            rowToggleButton: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                hidden: rowData.receivedQuantity === 0,
                clickable: true,
              },
            }),
          }"
          :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
        >
          <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines" #header>
            <Checkbox
              v-model="checkedAllRows"
              :binary="true"
              data-testid="purchase-order-line-all-checkbox"
              @click.stop
            />
          </template>
          <template v-if="col.field != PurchaseOrderLineColumns.ExpanderHandle" #body="{ data, field, index }">
            <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines">
              <Checkbox
                v-model="selectedRows"
                :value="data"
                data-testid="purchase-order-line-checkbox"
                :class="{ hidden: data.openQuantity <= 0 }"
                @click.stop
              />
            </template>
            <template v-if="col.field === PurchaseOrderLineColumns.PositionNumber">
              <span :data-testid="`purchase-order-line-position-number-${index}`">{{
                data[field as keyof typeof data]
              }}</span>
            </template>

            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductNumber">
              <span :data-testid="`purchase-order-line-product-number-${index}`">{{ data.product.productNumber }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.SupplierProductNumber">
              <span :data-testid="`purchase-order-line-supplier-product-number-${index}`">{{
                data.product.supplierProductNumber
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Gtin">
              <span :data-testid="`purchase-order-line-gtin-${index}`">{{ data.product.gtin }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductName">
              <span :data-testid="`purchase-order-line-product-name-${index}`">{{ data.product.name }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Quantity">
              <PurchaseOrderLineInput
                v-if="editingRowIndex === index"
                :id="`purchase-order-line-quantity-${index}`"
                input-class="w-20"
                :value="data[field as keyof typeof data]"
                :min-value="minQuantity(data)"
                :allow-empty="false"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                :set-focus="true"
                data-testid="purchase-order-line-quantity"
                @update:model-value="updateQuantity($event, data)"
                @keydown.enter.prevent="stopEditRow(index)"
                @keydown.esc.prevent="stopEditRow(index)"
              />

              <span v-if="editingRowIndex !== index" :data-testid="`purchase-order-line-quantity-${index}`" data>{{
                data[field as keyof typeof data]
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ReceivedQuantity">
              <span :data-testid="`purchase-order-line-received-${index}`">{{ data[field as keyof typeof data] }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.PurchasePrice">
              <PurchaseOrderLineInput
                v-if="editingRowIndex === index"
                :id="`purchase-order-line-supplier-cost-price-${index}`"
                data-testid="purchase-order-line-supplier-cost-price"
                :value="data[field as keyof typeof data]"
                :min-fraction-digits="2"
                :max-fraction-digits="2"
                input-class="w-32"
                :min="0"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                @on-update-value="onUpdatePurchasePrice($event, data)"
                @keydown.enter.prevent="stopEditRow(index)"
                @keydown.esc.prevent="stopEditRow(index)"
              />

              <span
                v-if="editingRowIndex !== index"
                :data-testid="`purchase-order-line-supplier-cost-price-${index}`"
                data
                >{{ n(data[field as keyof typeof data], "decimal") }}</span
              >
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ShippingDate">
              <PurchaseDate
                v-if="editingRowIndex === index"
                :id="`shipping-id-${index}`"
                v-model:date-time="data[field as keyof typeof data]"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                data-test-id="purchase-order-line-shipping-date"
                @update:date-time="updateShippingDate($event, data)"
                @keydown.esc.prevent="stopEditRow(index)"
              />
              <span v-if="editingRowIndex !== index" :data-testid="`purchase-order-line-shipping-date-${index}`" data>{{
                data[field as keyof typeof data]
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EstimatedArrivalDate">
              <PurchaseDate
                v-if="editingRowIndex === index"
                :id="`arrival-date-${index}`"
                v-model:date-time="data[field as keyof typeof data]"
                :disabled="!allowEdit || lineIsFullyReceived(data)"
                data-test-id="purchase-order-line-estimated-arrival-date"
                @update:date-time="updateEstimatedArrivalDate($event, data)"
                @keydown.esc.prevent="stopEditRow(index)"
              />
              <span
                v-if="editingRowIndex !== index"
                :data-testid="`purchase-order-line-estimated-arrival-date-${index}`"
                data
                >{{ data[field as keyof typeof data] }}</span
              >
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Confirmed">
              <span :data-testid="`purchase-order-line-confirmed-${index}`"
                ><Checkbox
                  :binary="true"
                  :model-value="data.confirmed"
                  :disabled="!allowEdit || lineIsFullyReceived(data)"
                  @update:model-value="updateConfirmed($event, data)"
                ></Checkbox
              ></span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.SumLine">
              <span :data-testid="`purchase-order-line-sum-${index}`">{{
                n(data[field as keyof typeof data], "currency", currencyIso)
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EditHandle">
              <EditHandle
                :index="index"
                :editing-row-index="editingRowIndex"
                @open-ellipsis-context-menu="(e) => openEllipsisContextMenu(e, data, index)"
                @stop-edit-row="stopEditRow"
              ></EditHandle>
            </template>
            <template v-else>
              {{ data[field as keyof typeof data] }}
            </template>
          </template>
        </Column>

        <template #expansion="slotProps">
          <GoodsReceptionHistory
            :goods-receptions="getGoodsReceptionsForPurchaseOrderLineId(slotProps.data.id)"
            :purchase-order-line-id="slotProps.data.id"
          />
        </template>
        <template #empty>
          <div class="c-table-empty">{{ t("purchase.empty-list") }}</div>
        </template>
      </DataTable>

      <RowContextMenu
        ref="ellipsisContextMenuRef"
        :disable-delete-option="disableDeleteOption"
        data-testId="row-context-menu"
        @open-product-info="openProductInfoDialog(selectedRow.product.id)"
        @select-row="selectRow(selectedRowIndex)"
        @delete-purchase-line="(e) => onConfirmDelete(e.originalEvent, selectedRow)"
      />
    </div>
    <PopOverColumnChooser
      ref="chooserRef"
      v-model:selected-columns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      @reset-columns="resetColumns"
    />

    <ProductInfoDialog v-if="showDialog" v-model:show-dialog="showDialog" :product-id="selectedRow.product.id" />
    <Dialog v-model:visible="selectedLinesDialogVisible" :modal="true" :class="'w-1/5'" :closable="false">
      <template #header>
        <div>{{ t("purchase.edit-date-selected-lines") }}</div>
      </template>
      <SelectedPurchaseOrderLinesDialog
        :selected-lines="props.selectedLines"
        @purchase-order-selected-lines="
          (confirmed: boolean, shippingDate: string, estimatedArrivalDate: string) =>
            purchaseOrderSelectedLines(confirmed, shippingDate, estimatedArrivalDate)
        "
        @cancel="selectedLinesDialogVisible = false"
      />
    </Dialog>
    <small v-if="val.purchaseOrderLines.$error" class="p-error" data-testid="purchase-order-line-error">
      {{ val.purchaseOrderLines.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import GoodsReceptionHistory from "./GoodsReceptionHistory.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import PurchaseOrderLineInput from "./PurchaseOrderLineInput.vue";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";
import cloneDeep from "lodash.clonedeep";
import { GoodsReception } from "@/models/goods-reception/GoodsReception";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import PurchaseOrderLinesToolbar from "./PurchaseOrderLinesToolbar.vue";
import { type DataTableRowContextMenuEvent } from "primevue/datatable";
import { PurchaseOrderLineColumns } from "@/models/purchase-order/PurchaseOrderLineColumns";
import { useTablePreferences } from "@cumulus/components";
import { type DataTableColumnExt, PopOverColumnChooser } from "@cumulus/components";
import RowContextMenu from "./RowContextMenu.vue";
import ProductInfoDialog from "./product-info/ProductInfoDialog.vue";
import SelectedPurchaseOrderLinesDialog from "./SelectedPurchaseOrderLinesDialog.vue";
import EditHandle from "./EditHandle.vue";
import { usePurchaseOrderLinesFunctions } from "@/utils/PurchaseOrderLinesFunctions";
import PurchaseDate from "./PurchaseDate.vue";

const { t, n } = useI18n();
const selectedLinesDialogVisible = ref(false);

const props = defineProps<{
  purchaseOrderLines: PurchaseOrderLine[];
  goodsReceptions: GoodsReception[];
  allowEdit: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  currencyIso: string;
  employeeId: string;
  selectedLines: PurchaseOrderLine[];
  initialPurchaseOrderLines: PurchaseOrderLine[];
}>();

const purchaseLineColumns = [
  {
    field: "selectPurchaseOrderLines",
    header: "purchase.product.checkbox",
    class: "w-12",
    sortable: false,
  },
  {
    field: "expanderHandle",
    header: "purchase.product.expander-empty",
    sortable: false,
    class: "w-12",
    expander: true,
  },
  { field: "positionNumber", header: "purchase.product.pos-nr", sortable: true },
  { field: "product.productNumber", header: "purchase.product.number", sortable: true },
  {
    field: "product.supplierProductNumber",
    header: "purchase.product.supplier-product-number",
    sortable: true,
  },
  { field: "product.gtin", header: "purchase.product.gtin", sortable: true },
  { field: "product.name", header: "purchase.product.name", sortable: true },
  {
    field: "quantity",
    header: "purchase.product.quantity",
    sortable: true,
    class: "c-col-quantity py-0",
  },
  {
    field: "receivedQuantity",
    header: "purchase.product.quantity-received",
    sortable: true,
    class: "py-0",
  },
  {
    field: "purchasePrice",
    header: "purchase.product.supplier-cost-price",
    sortable: true,
  },
  {
    field: "shippingDate",
    header: "purchase.product.shipping-date",
    sortable: true,
    class: "max-w-40 py-0",
  },
  {
    field: "estimatedArrivalDate",
    header: "purchase.product.eta-date",
    sortable: true,
    class: "max-w-40 py-0",
  },
  { field: "confirmed", header: "purchase.product.confirmed", sortable: false },
  { field: "sumLine", header: "purchase.product.sum-excl-vat", sortable: true, class: "text-right" },
  {
    field: "editHandle",
    header: "purchase.product.edit-handler",
    sortable: false,
    class: "p-0 w-4 p-4",
  },
];

const { selectedColumnsComputed, orderedColumns, renderKey, onColumnResizeEnd, onColumnReorder, resetColumns } =
  useTablePreferences("purchaseOrderLines", purchaseLineColumns, null);

const filteredColumns = computed(() => {
  if (props.purchaseOrderStatus && props.purchaseOrderStatus !== PurchaseOrderStatus.Open) {
    return orderedColumns.value;
  } else {
    return orderedColumns.value.filter(
      (col) =>
        col.field !== PurchaseOrderLineColumns.ExpanderHandle && col.field !== PurchaseOrderLineColumns.EditHandle,
    );
  }
});

const editingRowIndex = ref<number | null>(null);

const showDialog = ref<boolean>(false);
const previouslyFocusedRow = ref();
const ellipsisContextMenuRef = ref();
const selectedRow = ref<PurchaseOrderLine>(new PurchaseOrderLine());
const selectedRowIndex = ref<number>(0);

const addRowClass = (data: PurchaseOrderLine) => {
  if (lineIsFullyReceived(data)) return "c-line-done";
  return [`c-search-result-row c-sr-id-${data.id}`];
};

const purchaseOrderSelectedLines = (confirmed: boolean, shippingDate: string, estimatedArrivalDate: string) => {
  props.selectedLines.forEach((line) => {
    line.confirmed = confirmed;
    if (shippingDate !== "") line.shippingDate = shippingDate.split("T")[0];
    if (estimatedArrivalDate !== "") line.estimatedArrivalDate = estimatedArrivalDate.split("T")[0];
  });

  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  selectedLinesDialogVisible.value = false;
};

const selectedRows = ref<PurchaseOrderLine[]>([]);

watch(selectedRows, (newSelectedRows) => {
  if (newSelectedRows.length > 1) {
    if (editingRowIndex.value !== null) {
      stopEditRow(editingRowIndex.value);
    }
  }
  emit("selectionUpdated", newSelectedRows);
});

const onSelectionUpdated = () => {
  selectedRows.value = selectedRows.value.filter((row) => row.openQuantity > 0);
  if (selectedRows.value.length === 0) {
    const focusedElement = document.activeElement;
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          selectRow(index);
        }
      });
    }
  }
  emit("selectionUpdated", selectedRows.value);
};

const focusFirstPurchaseOrderLine = defineModel<boolean>("focusFirstPurchaseOrderLine", {
  required: true,
});

watch(
  () => focusFirstPurchaseOrderLine.value,
  (active) => {
    if (active === true) {
      nextTick(() => {
        onFocusFirstPurchaseOrderLine();
        focusFirstPurchaseOrderLine.value = false;
      });
    }
  },
  { deep: true },
);

const selectRow = (index: number) => {
  props.selectedLines.length > 1 ? (selectedLinesDialogVisible.value = true) : (editingRowIndex.value = index);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const getGoodsReceptionsForPurchaseOrderLineId = (purchaseOrderId: string) =>
  props.goodsReceptions.filter((x: GoodsReception) =>
    x.goodsReceptionLines.some((y) => y.purchaseOrderLineId === purchaseOrderId),
  );

const emit = defineEmits<{
  (e: "purchaseOrderLineDeleted", value: PurchaseOrderLine): void;
  (e: "update:purchaseOrderLines", value: PurchaseOrderLine[]): void;
  (e: "selectionUpdated", lines: PurchaseOrderLine[]): void;
  (e: "purchaseOrderPriceUpdated", purchaseOrderLine: PurchaseOrderLine, value: CalculationBase): void;
}>();

const expandedRows = ref([]);

const purchaseOrderLinesComputed = computed<PurchaseOrderLine[]>(() => {
  return cloneDeep(props.purchaseOrderLines);
});

const minQuantity = (purchaseOrderLine: PurchaseOrderLine) => {
  return purchaseOrderLine.receivedQuantity > 0 ? purchaseOrderLine.receivedQuantity : 1;
};

const updateQuantity = async (value: number, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.quantity = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  emit("purchaseOrderPriceUpdated", purchaseOrderLine, CalculationBase.PurchasePrice);
};

const deletePurchaseOrderLine = (purchaseOrderLine: PurchaseOrderLine) => {
  emit("purchaseOrderLineDeleted", purchaseOrderLine);
};

const updateShippingDate = (value: string, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.shippingDate = value.toString().split("T")[0];
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const onUpdatePurchasePrice = (value: number, purchaseOrderLine: PurchaseOrderLine) => {
  purchaseOrderLine.purchasePrice = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
  emit("purchaseOrderPriceUpdated", purchaseOrderLine, CalculationBase.PurchasePrice);
};

const updateEstimatedArrivalDate = (value: string, purchaseLine: PurchaseOrderLine) => {
  purchaseLine.estimatedArrivalDate = value.toString().split("T")[0];
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const updateConfirmed = (value: boolean, purchaseLine: PurchaseOrderLine) => {
  purchaseLine.confirmed = value;
  emit("update:purchaseOrderLines", purchaseOrderLinesComputed.value);
};

const rules = {
  purchaseOrderLines: {
    required: helpers.withMessage(
      t("validations.at-least-one", { property: t("purchase.purchasing-line").toLowerCase() }),
      required,
    ),
    minLength: minLength(1),
  },
};

const val = useVuelidate(rules, props);

const openProductInfoDialog = (productId: string) => {
  selectedRow.value.product.id = productId;
  showDialog.value = true;
};
const resetRowValues = (index: number) => {
  const initialValues = props.initialPurchaseOrderLines[index];
  const currentValues = props.purchaseOrderLines[index];

  if (currentValues != initialValues) {
    Object.assign(currentValues, initialValues);
  }
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const {
  handleKeyPress,
  tablePurchaseOrderLines,
  onFocusFirstPurchaseOrderLine,
  stopEditRow,
  onSelectOrderLine,
  checkedAllRows,
  onRowSelected,
  onRowUnselect,
  onConfirmDelete,
} = usePurchaseOrderLinesFunctions(
  selectedRowIndex,
  resetRowValues,
  setFocusedRow,
  openProductInfoDialog,
  selectRow,
  deletePurchaseOrderLine,
  purchaseOrderLinesComputed,
  props.purchaseOrderStatus,
  selectedRow,
  previouslyFocusedRow,
  editingRowIndex,
  selectedRows,
  props.selectedLines,
  selectedLinesDialogVisible,
);

const disableDeleteOption = ref(false);

const deleteOptionsRules = (data: PurchaseOrderLine): boolean => {
  if (props.allowEdit && data.status === PurchaseOrderStatus.Open) {
    return false;
  }

  if (data.status === PurchaseOrderStatus.Received) {
    return true;
  }

  if (data.status === PurchaseOrderStatus.PartiallyReceived && data.receivedQuantity === 0) {
    return false;
  }
  return true;
};

const openEllipsisContextMenu = (event: Event, data: PurchaseOrderLine, index: number) => {
  selectedRow.value = data;
  selectedRowIndex.value = index;
  setFocusedRow(index);
  disableDeleteOption.value = deleteOptionsRules(data);
  ellipsisContextMenuRef.value.openMenu(event);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  selectedRow.value = event.data;
  selectedRowIndex.value = event.index;
  setFocusedRow(event.index);
  disableDeleteOption.value = deleteOptionsRules(event.data);
  ellipsisContextMenuRef.value.openMenu(event.originalEvent);
};

const lineIsFullyReceived = (line: PurchaseOrderLine) => {
  return line.quantity - line.receivedQuantity === 0;
};

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value === false) {
      if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    }
  },
);
</script>

<style lang="scss" scoped>
:deep(.no-click) {
  pointer-events: none;
}

:deep(.c-line-done) {
  opacity: 0.6;
}

:deep(.no-click .clickable) {
  pointer-events: auto;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 22;
}

:deep(.p-inputnumber.p-component.p-inputwrapper) {
  width: 100%;
}

:deep(.p-datatable-tbody > tr.p-datatable-row-selected) {
  background: var(--p-datatable-row-focus-ring-color);
  color: var(--p-surface-950);
}

:deep(.c-purchase-order-table).p-datatable .p-datatable-tbody > tr {
  .c-purchase-orderline-select {
    display: none;
    color: var(--action-btn-bg);
    text-align: center;
  }

  .c-purchase-orderline-edit {
    display: block;
    color: var(--action-btn-bg);
    text-align: center;
  }

  .c-col-actions {
    padding: 0;
  }

  &:hover .c-purchase-orderline-select,
  &:focus .c-purchase-orderline-select {
    display: block;
    .p-button-icon {
      color: var(--action-btn-bg);
    }
    .c-delete-button .p-button-icon {
      color: var(--delete-btn-bg);
    }
  }
}

.c-purchase-orderline-close-button {
  padding: 0;
  color: var(--action-btn-bg);
}
:deep(.p-checkbox-checked .p-checkbox-box:hover) {
  background-color: var(--p-button-success-hover-background);
  border-color: var(--p-button-success-hover-background);
}

:deep(.p-checkbox-checked .p-checkbox-box) {
  background-color: var(--p-button-success-background);
  border-color: var(--p-button-success-background);
}
</style>
