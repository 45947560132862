import { httpClient, AuthHeaders } from "@cumulus/http";
import { NewPurchaseOrder } from "../../models/purchase-order/NewPurchaseOrder";
import { PurchaseOrder } from "../../models/purchase-order/PurchaseOrder";
import { PurchaseOrderLineInfo } from "../../models/purchase-order/PurchaseOrderLineInfo";
import { ProductOpenQuantity } from "../../models/purchase-order/ProductOpenQuantity";

class PurchaseOrderApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/purchase-orders"
        : `${import.meta.env.VITE_APP_API_URL as string}/purchase-orders`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<PurchaseOrder> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }

  public async getLatestOrdersForProduct(
    authHeaders: AuthHeaders,
    productId: string
  ): Promise<PurchaseOrderLineInfo[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/products/${productId}`, {
        params: { numberOfPurchaseOrders: 3 },
      })
      .then((response) => response.data);
  }

  public async getOpenQuantitiesForProducts(
    authHeaders: AuthHeaders,
    productIds: string[]
  ): Promise<ProductOpenQuantity[]> {
    const query = productIds.map((productId) => `productIds=${productId}`).join("&");

    return await httpClient(this.uri, authHeaders)
      .get("/products/open-quantities?" + query)
      .then((response) => response.data);
  }

  public async create(authHeaders: AuthHeaders, purchase: NewPurchaseOrder): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("", purchase);
  }

  public async update(authHeaders: AuthHeaders, purchase: PurchaseOrder): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("", purchase);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

const purchaseOrderApi = new PurchaseOrderApi();
export { purchaseOrderApi };
