<template>
  <div data-testid="purchase-order-dialog-container" class="items-center">
    <div class="grid grid-cols-3 gap-6">
      <div class="col-span-1 field text-center">
        <label>{{ t("purchase.modal-column-header.shipping-date") }}</label>
        <div class="mx-2">
          <CumulusDatePicker id="modal-shipping-date" v-model:date="shippingDate" dataTestId="modal-shipping-date" />
        </div>
      </div>
      <div class="col-span-1 field text-center">
        <label>{{ t("purchase.modal-column-header.eta-date") }}</label>
        <div class="mx-2">
          <CumulusDatePicker id="modal-eta-date" v-model:date="estimatedArrivalDate" dataTestId="modal-eta-date" />
        </div>
      </div>
      <div class="col-span-1 field text-center">
        <label>{{ t("purchase.modal-column-header.confirmed") }}</label>
        <div>
          <Checkbox :binary="true" v-model="confirmed" id="modal-confirmed" dataTestId="modal-confirmed"></Checkbox>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-10">
      <PrimeButton
        variant="text"
        @click="() => emit('cancel', false)"
        class="p-button-text w-full sm:w-auto"
        data-testid="selected-purchase-order-dialog-cancel-button"
      >
        {{ t("common.cancel") }}
      </PrimeButton>
      <PrimeButton
        variant="text"
        @click="() => emit('purchaseOrderSelectedLines', confirmed, shippingDate, estimatedArrivalDate)"
        class="p-button-primary w-full sm:w-auto"
        data-testid="receive-dialog-add-reference-button"
      >
        {{ t("common.ok") }}
      </PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDatePicker } from "@cumulus/components";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

const { t } = useI18n();

const selectedLines = defineModel<PurchaseOrderLine[]>("selectedLines");

const confirmed = ref<boolean>(false);
const shippingDate = ref<string>("");
const estimatedArrivalDate = ref<string>("");

const earliestShippingDate = (selectedLines.value ?? [])
  .map((line) => new Date(line.shippingDate))
  .reduce((earliest, current) => (current < earliest ? current : earliest), new Date());

shippingDate.value = earliestShippingDate.toISOString().split("T")[0];

const earliestEstimatedArrivalDate = (selectedLines.value ?? [])
  .map((line) => new Date(line.estimatedArrivalDate))
  .reduce((earliest, current) => (current < earliest ? current : earliest), new Date());

estimatedArrivalDate.value = earliestEstimatedArrivalDate.toISOString().split("T")[0];

const emit = defineEmits<{
  (e: "cancel", value: boolean): void;
  (e: "purchaseOrderSelectedLines", confirmed: boolean, shippingDate: string, estimatedArrivalDate: string): void;
}>();
</script>
