<template>
  <Popover ref="chooserRef" data-testid="column-list">
    <DataTable
      v-model:selection="selectedColumns"
      :value="columns"
      dataKey="field"
      tableStyle="min-width: 16rem"
      responsiveLayout="stack"
      breakpoint="991px"
      selectionMode="multiple"
      :metaKeySelection="false"
      :selectAll="selectAll"
      @select-all-change="onSelectAllChange"
      @row-select="emit('columnSelected', $event.data)"
      @row-unselect="emit('columnUnselected', $event.data)"
    >
      <div class="card flex justify-center">
        <div class="flex flex-col gap-4">
          <Column selectionMode="multiple" />
          <Column field="header">
            <template #body="{ data }">
              <span data-testid="column-value-row">{{ t(data.header) }}</span>
            </template>
          </Column>
        </div>
      </div>
    </DataTable>
    <div class="flex justify-end">
      <PrimeButton
        type="button"
        :label="t('common.reset')"
        class="w-full m-4"
        severity="danger"
        text
        data-testid="reset-column-button"
        @click="emit('resetColumns')"
        autoOptionFocus="false"
        :tabindex="-1"
      ></PrimeButton>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { DataTableColumn, DataTableColumnExt } from "@cumulus/components";
import { DataTableSelectAllChangeEvent } from "primevue";
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const columns = defineModel<DataTableColumn[]>("columns");
const selectedColumns = defineModel<DataTableColumn[] | DataTableColumnExt[]>("selectedColumns");
const selectAll = defineModel<boolean>("selectAll");
const onSelectAllChange = defineModel<(data: DataTableSelectAllChangeEvent) => void>("onSelectAllChange");

const emit = defineEmits<{
  (e: "resetColumns"): void;
  (e: "update:onSelectAllChange"): void;
  (e: "update:visibleDialog", value: boolean): void;
  (e: "columnSelected", value: DataTableColumn): void;
  (e: "columnUnselected", value: DataTableColumn): void;
  (e: "update:selectedColumns", value: DataTableColumn[] | DataTableColumnExt[]): void;
}>();

const chooserRef = ref();
defineExpose({
  toggle: (event: Event) => chooserRef.value.toggle(event),
});
</script>
