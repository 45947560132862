<template>
  <Card>
    <template #title
      ><Toolbar
        :selected-icon="selectedIcon"
        :is-filter-visible="props.visible"
        :is-sorting-visible="isSortingVisible"
        :is-chooser-visible="isChooserVisible"
        @purchase-refresh="emit('purchaseRefresh')"
        @open-sort-list="openSortList"
        @open-column-chooser="openColumnChooser"
        @on-toggle-filter-sidebar="emit('onToggleFilterSidebar')"
      >
      </Toolbar>
    </template>
    <template #content>
      <div class="mx-4 my-0">
        <ContextMenu ref="cm" :model="menuModel" data-testid="purchase-context-menu" />

        <div class="flex items-left gap-2 mt-3">
          <PurchaseActiveFilters v-model:filters="filtersComputed" />
        </div>
        <div>
          <DataTable
            ref="purchaseOrderList"
            :key="renderKey as unknown as number"
            v-model:context-menu-selection="selectedPurchaseOrder"
            v-model:selection="selectedPurchaseOrder"
            :value="purchaseOrders"
            data-key="id"
            :auto-layout="true"
            responsive-layout="scroll"
            selection-mode="single"
            :sort-order="sortOrder"
            :sort-field="sortField"
            class="c-datatable"
            :loading="loading"
            :striped-rows="true"
            :paginator="true"
            :rows="pageSize"
            :lazy="true"
            :total-records="totalHits"
            :rows-per-page-options="[50, 100]"
            :current-page-report-template="
              t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
            "
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            tabindex="0"
            data-testid="purchase-order-search-result"
            :row-class="addGlobalSearchClass"
            scrollable
            scroll-height="75vh"
            :resizable-columns="true"
            column-resize-mode="fit"
            removable-sort
            :reorderable-columns="true"
            :pt="{
              rowPerPageDropdown: {
                style: {
                  border: '1px solid black',
                },
                input: {
                  class: 'bg-[#000]',
                  style: {
                    background: 'black !important',
                  },
                },
              },
            }"
            @row-select="onRowSelected"
            @row-dblclick="onRowDblClicked"
            @sort="onSort"
            @page="onPage"
            @row-contextmenu="onRowContextMenu"
            @column-resize-end="onColumnResizeEnd"
            @column-reorder="onColumnReorder"
          >
            <Column
              v-for="(col, subIndex) of selectedColumnsComputed as unknown as DataTableColumn[]"
              :key="col.field + '_' + subIndex"
              :field="col.field"
              :header="t(col.header)"
              :class="col.class"
              :sortable="col.sortable"
              :pt="{
                headerCell: {
                  id: col.field,
                },
              }"
              :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
            >
              <template #body="{ data, field, index: indexData }">
                <template v-if="col.field === PurchaseOrderListColumns.Registered">
                  <div class="flex justify-between items-center">
                    <span>{{ d(data.registered, "long") }}</span>
                  </div>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.RegisteredByEmployeeInitials">
                  <span>
                    {{ data.registeredByEmployeeInitials }}
                  </span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderNumber">
                  <span class="c-purchase-order-number-text" @click="onPurchaseNumberClicked(data)">{{
                    data.purchaseOrderNumber
                  }}</span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.SupplierNumber">
                  <span>{{ data.supplier.supplierNumber }}</span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.SupplierName">
                  <span>{{ data.supplier.name }}</span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderReference">
                  <span>{{ data.purchaseOrderReference }}</span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.FreightMethodname">
                  <span>{{ data.freightMethod.name }}</span>
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.TotalItems">
                  {{ n(data.sumTotalLines, "decimal") }}
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.QuantityOfPurchaseOrderLines">
                  {{ n(data.quantityOfPurchaseOrderLines) }}
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderStatus">
                  {{ t(`purchase.status.${data.purchaseOrderStatus.toLowerCase()}`) }}
                </template>
                <template v-else-if="col.field === PurchaseOrderListColumns.PDF">
                  <img
                    :data-testid="'btn-show-pdf-' + indexData"
                    :src="pdfIcon"
                    @click="$emit('downloadPdfBtnClicked', data.id)"
                  />
                </template>
                <template v-else>
                  {{ data[field as keyof typeof data] }}
                </template>
              </template>
            </Column>

            <template v-if="!loading" #empty>
              <span>{{ t("purchase.search.total-hits", 0) }}</span>
            </template>
          </DataTable>
          <PopOverSorting
            ref="op"
            v-model:selected-icon="selectedIcon"
            data-testid="sort-list"
            :search-columns="purchaseOrderSearchColumns"
            :default-sort-field="defaultSortField"
            @on-selected-row="onSelectedRow"
          ></PopOverSorting>

          <PopOverColumnChooser
            ref="chooserRef"
            v-model:selected-columns="selectedColumnsComputed"
            :columns="filteredColumns"
            :label="t('common.reset')"
            @reset-columns="resetColumns"
          />
        </div>
        <PrintDialog
          v-if="showPrintModal"
          v-model:visible-dialog="showPrintModal"
          :purchase-order="selectedPurchaseOrder"
        />
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { SearchPurchaseOrder } from "@/models/search/purchase-order/SearchPurchaseOrder";
import type {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { NIL as emptyUuid } from "uuid";
import { useRouter, useRoute } from "vue-router";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import PrintDialog from "./PrintDialog.vue";
import { PurchaseOrderListColumns } from "@/models/purchase-order/search/PurchaseOrderListColumns";
import PurchaseActiveFilters from "./PurchaseActiveFilters.vue";

import { useTablePreferences } from "@cumulus/components";
import { type DataTableColumn } from "@cumulus/components";
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";
import Toolbar from "./Toolbar.vue";
import { PopOverColumnChooser, PopOverSorting } from "@cumulus/components";

const { t, d, n } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const showPrintModal = ref(false);

const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const cm = ref();
const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const purchaseOrderSearchColumns = [
  { field: "registered", header: "purchase.search.registered", sortable: true },
  {
    field: "registeredByEmployeeInitials",
    header: "purchase.search.registered-by",
    sortable: true,
  },
  {
    field: "purchaseOrderNumber",
    header: "purchase.search.purchase-number",
    sortable: true,
  },
  {
    field: "supplier.supplierNumber",
    header: "purchase.search.supplier-number",
    sortable: true,
  },
  { field: "supplier.name", header: "purchase.search.supplier-name", sortable: true },
  {
    field: "purchaseOrderReference",
    header: "purchase.search.purchase-reference",
    sortable: true,
  },
  {
    field: "freightMethod.name",
    header: "purchase.search.freight-method",
    sortable: true,
  },
  { field: "totalItems", header: "purchase.search.total-items-ex-vat", sortable: true },
  {
    field: "quantityOfPurchaseOrderLines",
    header: "purchase.search.quantity-order-lines",
    sortable: true,
  },
  {
    field: "purchaseOrderStatus",
    header: "purchase.search.purchase-status",
    sortable: true,
  },
  {
    field: "pdf",
    header: "purchase.search.pdf",
    sortable: false,
    class: "c-pdf-icon w-4 p-4",
  },
];

const props = defineProps<{
  purchaseOrders: SearchPurchaseOrder[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  filters: PurchaseOrderSearchFilters;
  visible: boolean;
}>();

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("purchaseOrderSearch", purchaseOrderSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const purchaseOrderList = ref();
const selectedPurchaseOrder = ref<SearchPurchaseOrder>(new SearchPurchaseOrder());

const addGlobalSearchClass = (data: SearchPurchaseOrder) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedPurchaseOrder.value?.id === data?.id },
  ];
};

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:pageSize", value: number): void;
  (e: "downloadPdfBtnClicked", value: string): void;
  (e: "purchaseRefresh"): void;
  (e: "update:filters", value: PurchaseOrderSearchFilters): void;
  (e: "onToggleFilterSidebar"): void;
}>();

watch([() => props.loading, () => props.purchaseOrders], ([loading, purchaseOrders]) => {
  if (loading === false && purchaseOrders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const focusSearchResult = (id = "") => {
  if (props.purchaseOrders.length > 0 && purchaseOrderList.value) {
    const element = id === "" ? null : purchaseOrderList.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    purchaseOrderList.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(
  purchaseOrderSearchColumns.find((column) => column.field === PurchaseOrderListColumns.SupplierName),
);
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult(selectedPurchaseOrder.value.id);
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: PurchaseOrderSearchFilters) => {
    emit("update:filters", value);
  },
});

const openPurchaseOrder = (purchaseOrderId: string) => {
  purchaseOrderId != null && purchaseOrderId !== emptyUuid
    ? router.push({ name: "purchase-edit", params: { id: purchaseOrderId } })
    : toast.add({
        severity: "warn",
        summary: t("purchase.search.toast.open-purchase-failed"),
        detail: t("purchase.search.toast.no-purchase-selected"),
        closable: true,
      });
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openPurchaseOrder(event.data.id as string);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openPurchaseOrder(event.data.id as string);
};

const onPurchaseNumberClicked = (purchaseOrder: SearchPurchaseOrder) => {
  selectedPurchaseOrder.value = purchaseOrder;
  openPurchaseOrder(purchaseOrder.id);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>

<style scoped lang="scss">
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
</style>
