<template>
  <div class="mt-1">
    <FloatLabel variant="on">
      <InputNumber
        id="shipping-price"
        v-model="shippingPrice"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('purchase.shipping-price').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testId="shipping-price"
        :class="{ 'p-disabled': !allowEdit }"
        :disabled="!allowEdit"
        :fluid="true"
      />
      <label for="shipping-price">{{ t("purchase.shipping-price") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const shippingPrice = defineModel<number>("shippingPrice");

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>
