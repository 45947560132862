<template>
  <ContextMenu ref="ellipsisContextMenuRef" :model="menuModel">
    <template #item="{ item, props: propsItem }">
      <div class="flex flex-row w-fit p-1 gap-2" v-bind="propsItem.action">
        <div class="w-6"><span :class="item.icon" /></div>
        <div class="w-28 text-sm">
          <span class="ml-2">{{ item.label }}</span>
        </div>
        <div class="text-xs">
          <span
            v-if="item.shortcut"
            class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1"
            >{{ item.shortcut }}</span
          >
          <i v-if="item.items" class="pi pi-angle-right ml-auto"></i>
        </div>
      </div>
    </template>
  </ContextMenu>
</template>

<script setup lang="ts">
import ContextMenu from "primevue/contextmenu";
import type { MenuItem } from "primevue/menuitem";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  disableDeleteOption: boolean;
}>();

const emit = defineEmits<{
  (event: "openProductInfo"): void;
  (event: "selectRow"): void;
  (event: "deletePurchaseLine", value: { originalEvent: Event }): void;
}>();

const ellipsisContextMenuRef = ref();

const createMenu = () => {
  return [
    {
      label: t("purchase.context-menu.edit"),
      icon: "pi pi-fw pi-pencil",
      command: () => {
        emit("selectRow");
      },
      shortcut: "P",
    },
    {
      label: t("purchase.context-menu.product-info"),
      icon: "pi pi-fw pi-info",
      shortcut: "Enter/Double click",
      command: () => {
        emit("openProductInfo");
      },
    },
    {
      label: t("purchase.context-menu.delete"),
      icon: "pi pi-fw pi-trash",
      disabled: props.disableDeleteOption,
      command: (event: { originalEvent: Event }) => {
        emit("deletePurchaseLine", event);
      },
      shortcut: "Del",
    },
  ];
};

const menuModel = ref([] as MenuItem[]);
const updateMenuModel = () => {
  menuModel.value = createMenu();
};

watch(
  () => [props.disableDeleteOption],
  () => {
    updateMenuModel();
  },
);

onMounted(() => {
  updateMenuModel();
});

const openMenu = (event: Event) => {
  if (ellipsisContextMenuRef.value !== undefined) {
    ellipsisContextMenuRef.value.show(event);
  } else {
    console.error("Context menu reference is not initialized.");
  }
};
defineExpose({ openMenu });
</script>
