<template>
  <div v-if="useTeleport" id="purchase-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ editMode ? t("purchase.header.edit-purchase") : t("purchase.header.add-purchase") }}</h1>
    </Teleport>
  </div>

  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        :disabled="props.isSaving"
        data-testid="btn-cancel"
        variant="text"
        size="small"
        @click="emit('onCancel')"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="font-normal">{{ props.title }}</span>
      </Button>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
        <Button
          id="btn-save"
          class="c-circular-button mr-1"
          data-testid="btn-save"
          :disabled="!isPurchaseOrderChanged || isSaving || !allowEditPurchaseOrder"
          @click="emit('saveClicked')"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </Button>
      </div>

      <Button
        v-if="editMode"
        class="c-circular-button ml-4"
        :disabled="!allowDelete"
        data-testid="btn-delete"
        @click="onConfirmDelete"
      >
        <i class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.delete") }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();
const confirm = useConfirm();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

const props = defineProps<{
  isPurchaseOrderChanged: boolean;
  isSaving: boolean;
  allowEditPurchaseOrder: boolean;
  inGoodsReception?: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  editMode?: boolean;
  loading: boolean;
  title: string;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "deleteClicked"): void;
  (e: "onCancel"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("purchase.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const allowDelete = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  if (!props.editMode) return false;
  if (props.isSaving) return true;
  if (props.purchaseOrderStatus && props.purchaseOrderStatus === PurchaseOrderStatus.Open) return true;

  return false;
});

const saveButtonTooltip = computed(() => {
  return props.isPurchaseOrderChanged
    ? { value: "" }
    : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style lang="scss" scoped>
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
