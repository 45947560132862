<template>
  <div id="purchase-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ editMode ? t("purchase.header.edit-purchase") : t("purchase.header.add-purchase") }}</h1>
    </Teleport>
  </div>

  <div class="c-toolbar-wrapper bg-surface-100">
    <div class="flex flex-wrap">
      <div class="min-w-full lg:min-w-0 lg:w-5/12 content-center">
        <PrimeButton
          id="btn-cancel"
          icon="pi pi-arrow-left"
          :disabled="props.isSaving"
          data-testid="btn-cancel"
          rounded
          size="small"
          variant="text"
          @click="emit('onCancel')"
        />
        <div class="inline-block ml-4 text-lg">
          <span v-if="!loading" class="text-slate-800">{{ props.title }}</span>
          <ProgressSpinner v-else class="w-5 h-5 ml-2" />
        </div>
      </div>

      <div class="min-w-full lg:min-w-0 lg:w-1/12 mb-4 lg:mb-0 text-center content-center"></div>

      <div class="min-w-full lg:min-w-0 lg:w-6/12 mb-2 lg:mb-0 text-center lg:text-right content-center">
        <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
          <PrimeButton
            class="c-circular-button mr-1"
            @click="emit('saveClicked')"
            data-testid="btn-save"
            id="btn-save"
            :disabled="!isPurchaseOrderChanged || isSaving || !allowEditPurchaseOrder"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>

        <PrimeButton
          v-if="editMode"
          class="c-circular-button ml-4"
          @click="onConfirmDelete"
          :disabled="!allowDelete"
          data-testid="btn-delete"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="props.unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToPurchaseOrderSearch')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed } from "vue";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();
const confirm = useConfirm();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

const props = defineProps<{
  isPurchaseOrderChanged: boolean;
  isSaving: boolean;
  allowEditPurchaseOrder: boolean;
  inGoodsReception?: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  editMode?: boolean;
  unsavedChangesDialogVisible: boolean;
  loading: boolean;
  title: string;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "deleteClicked"): void;
  (e: "onCancel"): void;
  (e: "stayOnPage"): void;
  (e: "routeToPurchaseOrderSearch"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("purchase.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const allowDelete = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  if (!props.editMode) return false;
  if (props.isSaving) return true;
  if (props.purchaseOrderStatus && props.purchaseOrderStatus === PurchaseOrderStatus.Open) return true;

  return false;
});

const saveButtonTooltip = computed(() => {
  return props.isPurchaseOrderChanged
    ? { value: "" }
    : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style lang="scss" scoped>
.c-toolbar.c-fixed-right {
  position: fixed;
  right: 1rem;
}

.c-circular-button:disabled {
  opacity: 0.6;
}

.c-toolbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  height: 3.5rem;
  align-content: center;
}
</style>
