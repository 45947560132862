export function usePurchaseOrderRouteService(purchaseOrderId: Ref<string> | null) {
  const router = useRouter();

  const routeToPurchaseOrderSearch = async () => {
    if (window.history.state.back === null || window.history.state.back.indexOf("/purchase/search") === -1) {
      router.push({ name: "purchase-search", query: { search: "" } });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      if (purchaseOrderId !== null) {
        url.searchParams.set("focusResult", purchaseOrderId.value);
      }

      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }
  };

  const paramsToObject = (entries: URLSearchParams) => {
    const result = {} as Record<string, string>;
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  return {
    routeToPurchaseOrderSearch,
  };
}
