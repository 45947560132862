<template>
  <PurchaseOrderHeader
    :is-purchase-order-changed="isPurchaseOrderChanged"
    :is-saving="isSaving"
    :allow-edit-purchase-order="true"
    :in-goods-reception="false"
    :purchase-order-status="PurchaseOrderStatus.Open"
    :edit-mode="false"
    :loading="loading"
    :title="t('common.new')"
    @on-cancel="purchaseOrderRouteService.routeToPurchaseOrderSearch"
    @save-clicked="onSave"
  />

  <div class="ml-2 my-2" data-testid="add-purchase">
    <div class="c-content">
      <div class="c-content-top">
        <div class="c-content-top-left mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6">
                  <div class="mb-4">
                    <SupplierInput
                      v-model:focus-search-input="focusSupplierSearchInput"
                      :supplier="newPurchaseOrder.supplier"
                      :purchase-order-lines="newPurchaseOrder.purchaseOrderLines"
                      @selected-supplier="selectedSupplier"
                    />
                  </div>

                  <div class="mb-6">
                    <ContactInput
                      :contact="newPurchaseOrder.supplier.contact"
                      :supplier-contacts="supplierContacts"
                      @selected-contact="selectedContact"
                    />
                  </div>

                  <div class="mb-2">
                    <ContactEmailInput v-model:email="newPurchaseOrder.supplier.contact.email" />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="-mt-3.5">
                    <InformationTabs
                      v-model:delivery="newPurchaseOrder.delivery"
                      :supplier="newPurchaseOrder.supplier"
                      :allow-edit="true"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-4">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 mx-1">
                  <div class="mb-6">
                    <CommentInput v-model:comment="newPurchaseOrder.comment" :in-goods-reception="false" />
                  </div>
                  <div class="mt-4 mb-6">
                    <PurchaseOrderReference
                      v-model:purchase-order-reference="newPurchaseOrder.purchaseOrderReference"
                    />
                  </div>
                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-6">
                      <div class="mb-2 mr-6">
                        <FreightMethod
                          v-model:freight-method="newPurchaseOrder.freightMethod"
                          :default-freight-method-id="freightMethodIdFromSupplier"
                        />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-6">
                      <div class="mb-2">
                        <ShippingPrice
                          v-model:shipping-price="newPurchaseOrder.shippingPrice"
                          class="px-6"
                          :currency-iso="newPurchaseOrder.currencyIso"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-col-2 -mx-4">
                  <div class="mt-1 mb-6 ml-4 mr-4">
                    <PurchaseWarehouse
                      v-model:warehouse-id="newPurchaseOrder.warehouseId"
                      :purchase-order-lines="newPurchaseOrder.purchaseOrderLines"
                      @update-purchase-order-delivery="updatePurchaseOrderDelivery($event)"
                    />
                  </div>

                  <div class="mt-5 ml-4 mr-4 mb-4">
                    <Incoterms v-model:selected-incoterms="newPurchaseOrder.supplier.incoterms" />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1">
                      <div class="mb-2 mt-4 ml-4">
                        <PaymentTerms
                          v-model:payment-term="newPurchaseOrder.paymentTerm"
                          :payment-term-id-from-supplier="paymentTermIdFromSupplier"
                        />
                      </div>
                    </div>
                    <div class="c-col-2">
                      <div class="mb-2 mt-4 ml-6 mr-4">
                        <PurchaseCurrency
                          v-model:currency-iso="newPurchaseOrder.currencyIso"
                          :currencies="currencies"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div>
        <Card class="c-font-size mb-0 rounded-b-none mx-2">
          <template #content>
            <PurchaseOrderLines
              ref="purchaseLinesRef"
              v-model:purchase-order-lines="newPurchaseOrder.purchaseOrderLines"
              :focus-first-purchase-order-line="false"
              :goods-receptions="[]"
              :allow-edit="true"
              :purchase-order-status="PurchaseOrderStatus.Open"
              :currency-iso="newPurchaseOrder.currencyIso"
              :employee-id="employeeId"
              :selected-lines="selectedLines"
              :initial-purchase-order-lines="initialPurchaseOrderLines"
              @purchase-order-line-deleted="deletePurchaseOrderLine"
              @purchase-order-price-updated="onPurchaseOrderPriceUpdated"
              @selection-updated="(lines) => (selectedLines = lines)"
            />
            <ProductSearch
              ref="productSearchRef"
              :purchase-order-lines="newPurchaseOrder.purchaseOrderLines"
              :allow-search="true"
              :selected-supplier-id="newPurchaseOrder.supplier.id"
              :warehouse-id="newPurchaseOrder.warehouseId"
              :currencies="currencies"
              :selected-currency-iso="newPurchaseOrder.currencyIso"
              @create-new-order-line="createNewOrderLine"
              @show-supplier-dialog="showSupplierDialog"
            />
          </template>
        </Card>
      </div>
    </div>

    <SelectSupplierDialog
      v-if="supplierDialogVisible"
      v-model:show-dialog="supplierDialogVisible"
      :supplier-ids="supplierIds"
      @supplier-selected="onSupplierSelected"
    />
  </div>

  <PurchaseOrderFooter
    v-model:shipping-price="newPurchaseOrder.shippingPrice"
    :sum-total-lines="sumTotalLines"
    :total-sum="totalSum"
    :currency-iso="newPurchaseOrder.currencyIso"
    :allow-edit="true"
    @re-calculate-purchase-order-total="reCalculatePurchaseOrderTotal"
  />
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut, useUnsavedChanges } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";

//PurchaseOrder
import { useNewPurchaseOrderStore } from "@/stores/NewPurchaseOrderStore";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import { Address } from "@/models/purchase-order/Address";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { usePurchaseOrderRouteService } from "@/api/purchase-order/PurchaseOrderRouteService";

//Calculations
import { usePurchaseOrderCalculation } from "@/utils/calculation/PurchaseOrderCalculation";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";

//Supplier
import { Supplier } from "@/models/supplier/Supplier";
import { SupplierContact } from "@/models/supplier/SupplierContact";

//Search
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { SearchProduct } from "@/models/search/product/SearchProduct";

//currency
import { useCurrencyService } from "@/api/currency/CurrencyService.ts";
import { Currency } from "@/models/currency/Currency";

import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();
const val = useValidate();
const { t } = useI18n();
const currencies = ref<Currency[]>([]);
const supplierContacts = ref<SupplierContact[]>([]);
const freightMethodIdFromSupplier = ref<string>("");
const paymentTermIdFromSupplier = ref<string>("");
const productSearchRef = ref();
const focusSupplierSearchInput = ref(true);

const selectedLines = ref<PurchaseOrderLine[]>([]);

const sumTotalLines = ref(0);
const totalSum = ref(0);
const initialPurchaseOrderLines = ref<PurchaseOrderLine[]>([]);

const { getAllCurrencies } = useCurrencyService();

const newPurchaseOrderStore = useNewPurchaseOrderStore();
const { newPurchaseOrder, isPurchaseOrderChanged, isSaving, loading } = storeToRefs(newPurchaseOrderStore);

const purchaseOrderRouteService = usePurchaseOrderRouteService(null);

const { calculateSumLineTotalPrice, calculateSumTotalLines, calculateTotalSum } = usePurchaseOrderCalculation();
const employeeId = ref<string>("");
onMounted(async () => {
  await newPurchaseOrderStore.clearStore();
  employeeId.value = (await getUser()).getEmployee().id;
  await newPurchaseOrderStore.setEmployee();
  await newPurchaseOrderStore.setDefaultWarehouseAndDelivery();
  await fetchCurrencies();
});

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const onPurchaseOrderPriceUpdated = (purchaseOrderLine: PurchaseOrderLine, calculationBase: CalculationBase) => {
  const sumLine = calculateSumLineTotalPrice(purchaseOrderLine, calculationBase);
  const line = newPurchaseOrder.value.purchaseOrderLines.find((line) => line.id === purchaseOrderLine.id);
  if (line) {
    line.purchasePrice = purchaseOrderLine.purchasePrice;
    line.sumLine = sumLine;
  }

  sumTotalLines.value = calculateSumTotalLines(newPurchaseOrder.value.purchaseOrderLines);
  totalSum.value = calculateTotalSum(newPurchaseOrder.value.purchaseOrderLines, newPurchaseOrder.value.shippingPrice);
};

const reCalculatePurchaseOrderTotal = () => {
  sumTotalLines.value = calculateSumTotalLines(newPurchaseOrder.value.purchaseOrderLines);
  totalSum.value = calculateTotalSum(newPurchaseOrder.value.purchaseOrderLines, newPurchaseOrder.value.shippingPrice);
};

const updatePurchaseOrderDelivery = (warehouse: Warehouse) => {
  if (warehouse) {
    newPurchaseOrder.value.delivery.name = warehouse.name;
    newPurchaseOrder.value.delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);
  }
};

const toast = useCumulusToast(useToast());

const onSave = async () => {
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }
  val.value.$touch();

  if (!(await val.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("purchase.add.toast.validation.summary"),
      detail: t("purchase.add.toast.validation.detail"),
      closable: true,
    });
    return;
  }

  if (await newPurchaseOrderStore.createOrder()) {
    resetView();
  }
};

const supplierDialogVisible = ref<boolean>(false);
const supplierIds = ref<string[]>([]);
const selectedProductForPurchaseOrder = ref<SearchProductForPurchaseOrder>({} as SearchProductForPurchaseOrder);
const selectedSearchProduct = ref<SearchProduct>({} as SearchProduct);

const showSupplierDialog = (product: SearchProductForPurchaseOrder, searchProduct: SearchProduct) => {
  selectedSearchProduct.value = searchProduct;
  selectedProductForPurchaseOrder.value = product;

  supplierIds.value = product.supplierIds;
  supplierDialogVisible.value = true;
};

const onSupplierSelected = async (supplier: Supplier) => {
  selectedSupplier(supplier);

  const supplierPrice = selectedSearchProduct.value.supplierPrices.find((price) => price.supplierId === supplier.id);
  const [supplierPurchasePrice] = SearchProductForPurchaseOrder.getSupplierCostPriceForSupplier(supplierPrice);

  selectedProductForPurchaseOrder.value.supplierPurchasePrice = supplierPurchasePrice;
};

const createNewOrderLine = async (product: SearchProductForPurchaseOrder, searchProduct: SearchProduct) => {
  selectedSearchProduct.value = searchProduct;
  selectedProductForPurchaseOrder.value = product;

  await addPurchaseOrderLine(product);
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

const purchaseLinesRef = ref();
const resetView = async () => {
  val.value.$reset();
  window.scrollTo(0, 0);
  await newPurchaseOrderStore.clearStore();
  sumTotalLines.value = 0;
  totalSum.value = 0;
  paymentTermIdFromSupplier.value = "";
  freightMethodIdFromSupplier.value = "";
  productSearchRef.value.reset();
};

const deletePurchaseOrderLine = (purchaseLine: PurchaseOrderLine) => {
  const index = newPurchaseOrder.value.purchaseOrderLines?.findIndex((item) => item.id === purchaseLine.id);
  if (index !== -1) {
    newPurchaseOrder.value.purchaseOrderLines?.splice(index, 1);
    for (let index = 0; index < newPurchaseOrder.value.purchaseOrderLines.length; index++) {
      newPurchaseOrder.value.purchaseOrderLines[index].positionNumber = index;
    }
  }
};

const addPurchaseOrderLine = async (product: SearchProductForPurchaseOrder) => {
  const purchaseOrderLine = PurchaseOrderLine.Create(product);
  purchaseOrderLine.quantity = product.quantity ?? 1;
  purchaseOrderLine.purchasePrice = product.supplierPurchasePrice ?? 0;
  purchaseOrderLine.shippingDate = product.shippingDate ?? new Date(Date.now()).toDateOnlyString();
  purchaseOrderLine.estimatedArrivalDate = product.estimatedArrivalDate ?? new Date(Date.now()).toDateOnlyString();
  newPurchaseOrder.value.purchaseOrderLines.push(purchaseOrderLine);
  purchaseOrderLine.positionNumber = newPurchaseOrder.value.purchaseOrderLines.length;

  await reCalculatePurchaseOrderTotal();
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

useShortcut(ShortcutAction.save, onSave);

const selectedSupplier = (supplier: Supplier) => {
  newPurchaseOrder.value.supplier = PurchaseOrderSupplier.createFromSupplier(supplier);
  supplierContacts.value = supplier.contacts;
  newPurchaseOrder.value.currencyIso = supplier.currency?.name ?? "";

  paymentTermIdFromSupplier.value = supplier.defaultPaymentTermId;
  freightMethodIdFromSupplier.value = supplier.freightMethodId;
};

const selectedContact = (contact: SupplierContact) => {
  newPurchaseOrder.value.supplier.contact = contact;
};

useUnsavedChanges(isPurchaseOrderChanged);
</script>

<style lang="scss" scoped>
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}
</style>
