import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { useAuth } from "@cumulus/event-bus";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { PurchaseOrder } from "@/models/purchase-order/PurchaseOrder";
import { purchaseOrderApi } from "@/api/purchase-order/PurchaseOrderApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { useEmployee } from "@/api/employee/EmployeeService";
import { PurchaseOrderDelivery } from "@/models/purchase-order/PurchaseOrderDelivery";
import { Address } from "@/models/purchase-order/Address";
import { Employee } from "@/models/employee/Employee";

export const useEditPurchaseOrderStore = defineStore("editPurchaseOrderStore", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { getEmployee } = useEmployee();
  const { getWarehouse } = useWarehouse();
  const { t } = useI18n();
  const toast = useCumulusToast(useToast());

  let initialPurchaseOrder = new PurchaseOrder();
  let employee: Employee | null = null;

  const editPurchaseOrder = ref<PurchaseOrder>(new PurchaseOrder());
  const selectedProductId = ref<string | null>(null);
  const advancedProductSearch = ref(false);
  const focusFirstOrderLine = ref(false);

  const loading = ref(false);
  const isSaving = ref(false);

  const clearStore = async () => {
    initialPurchaseOrder = new PurchaseOrder();
    editPurchaseOrder.value = new PurchaseOrder();
    await setEmployee();
    await setDefaultWarehouseAndDelivery();
    selectedProductId.value = null;
    advancedProductSearch.value = false;
  };

  const getOrder = async (id: string): Promise<PurchaseOrder | null> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();

      if (editPurchaseOrder.value?.id === id) {
        return editPurchaseOrder.value;
      }

      await clearStore();

      const orderResult = await purchaseOrderApi.get(authHeaders, id);

      if (!orderResult) {
        return null;
      }

      editPurchaseOrder.value = orderResult;
      initialPurchaseOrder = cloneDeep(orderResult);

      focusFirstOrderLine.value = true;

      return editPurchaseOrder.value;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return null;
  };

  const updateOrder = async (): Promise<boolean> => {
    let success = true;
    try {
      isSaving.value = true;
      const authHeaders = await getAuthHeaders();
      await purchaseOrderApi.update(authHeaders, editPurchaseOrder.value);
      initialPurchaseOrder = cloneDeep(editPurchaseOrder.value);

      toast.add({
        severity: "success",
        summary: t("purchase.edit.toast.success.summary"),
        detail: t("purchase.edit.toast.success.detail"),
        closable: true,
      });
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      isSaving.value = false;
    }

    return success;
  };

  const deleteOrder = async (id: string): Promise<boolean> => {
    let success = true;
    try {
      const authHeaders = await getAuthHeaders();
      await purchaseOrderApi.delete(authHeaders, id);

      toast.add({
        severity: "success",
        summary: t("purchase.delete.toast.success.summary"),
        detail:
          editPurchaseOrder.value.supplier.contact.firstName + " " + editPurchaseOrder.value.supplier.contact.lastName,
        closable: true,
      });

      await clearStore();
    } catch (error) {
      await handleError(error);
      success = false;
    }

    return success;
  };

  const allowEditPurchaseOrder = computed<boolean>(() => {
    return (
      editPurchaseOrder.value.purchaseOrderStatus !== PurchaseOrderStatus.Received &&
      !editPurchaseOrder.value.inGoodsReception
    );
  });

  const isPurchaseOrderChanged = ref(false);
  const isChanged = () => {
    if (initialPurchaseOrder.purchaseOrderStatus === PurchaseOrderStatus.Received) {
      return false;
    }
    return !deepEqual(editPurchaseOrder.value, initialPurchaseOrder);
  };
  watch(editPurchaseOrder, () => (isPurchaseOrderChanged.value = isChanged()), { deep: true });

  const editPurchaseOrderId = computed<string>(() => {
    return editPurchaseOrder.value.id;
  });

  const setEmployee = async () => {
    if (employee === null) {
      employee = await getEmployee();
    }

    const fullname = employee.firstName + " " + employee.lastName;
    const initials = employee.initials;

    editPurchaseOrder.value.registeredByEmployeeName = fullname;
    editPurchaseOrder.value.registeredByEmployeeInitials = initials;

    initialPurchaseOrder.registeredByEmployeeName = fullname;
    initialPurchaseOrder.registeredByEmployeeInitials = initials;
  };

  const setDefaultWarehouseAndDelivery = async () => {
    if (employee === null) {
      employee = await getEmployee();
    }

    editPurchaseOrder.value.warehouseId = employee.warehouseId;
    initialPurchaseOrder.warehouseId = employee.warehouseId;

    const warehouse = await getWarehouse(employee.warehouseId);
    const delivery = new PurchaseOrderDelivery();
    delivery.name = warehouse.name;
    delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);

    editPurchaseOrder.value.delivery = delivery;
    initialPurchaseOrder.delivery = delivery;
  };

  const deepEqual = (x: unknown, y: unknown): boolean => {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x as { [key: string]: unknown }).length === ok(y as { [key: string]: unknown }).length &&
          ok(x as { [key: string]: unknown }).every((key) =>
            deepEqual((x as { [key: string]: unknown })[key], (y as { [key: string]: unknown })[key])
          )
      : x === y;
  };

  return {
    isSaving,
    loading,
    editPurchaseOrder,
    selectedProductId,
    allowEditPurchaseOrder,
    isPurchaseOrderChanged,
    advancedProductSearch,
    focusFirstOrderLine,
    editPurchaseOrderId,
    clearStore,
    getOrder,
    updateOrder,
    deleteOrder,
    setEmployee,
    setDefaultWarehouseAndDelivery,
  };
});
