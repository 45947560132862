<template>
  <h2 class="text-center font-bold mb-4 mt-2">{{ t("purchase.search.filter-purchase-order-date") }}</h2>

  <div>
    <div class="mb-4">
      <CumulusDatePicker
        id="from-date-filter"
        v-model:date="fromDateComputed"
        :label="t('purchase.search.filter-date-from', { symbol: ':' })"
        data-test-id="from-date-filter"
        :debounce-time="400"
      />
    </div>
    <div class="mb-4">
      <CumulusDatePicker
        id="to-date-filter"
        v-model:date="toDateComputed"
        :label="t('purchase.search.filter-date-to', { symbol: ':' })"
        data-test-id="to-date-filter"
        :debounce-time="400"
      />
    </div>
  </div>

  <Button
    :label="t('purchase.search.filter-last-thirty-days')"
    data-testid="last-30-days-filter-btn"
    severity="secondary"
    variant="outlined"
    class="w-full dark:border-surface-600 border"
    @click="setDateFilterLastDays(30)"
  >
  </Button>

  <hr class="c-seperator-line" />

  <h2 class="text-center font-bold">{{ t("purchase.search.filter-status") }}</h2>
  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex relative p-2 text-left rounded-md dark:border-surface-600 border mb-4"
  >
    <div class="centered-child">
      <label class="text-sm" :for="'purchase-status-' + value.status.toLowerCase()">{{
        t(`purchase.status.${value.status.toLowerCase()}`)
      }}</label>
    </div>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :input-id="'purchase-status-' + value.status.toLowerCase()"
        :data-testid="'purchase-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>

  <hr class="c-seperator-line" />

  <FloatLabel variant="on">
    <AutoComplete
      v-model="supplierNumbersComputed"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('purchase.search.filter-supplier-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-supplier-numbers"
      data-testid="supplier-number-filter"
      input-id="supplier-number-filter"
      multiple
      fluid
      :typeahead="false"
    />
    <label for="supplier-number-filter">
      {{ t("purchase.search.filter-supplier-number") }}
    </label>
  </FloatLabel>
  <hr class="c-seperator-line" />

  <FloatLabel variant="on">
    <AutoComplete
      v-model="productNumbersComputed"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('purchase.search.filter-product-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-product-numbers"
      data-testid="product-number-filter"
      input-id="product-number-filter"
      multiple
      fluid
      :typeahead="false"
    />
    <label for="product-number-filter" class="text-center">{{ t("purchase.search.filter-product-number") }}</label>
  </FloatLabel>

  <hr class="c-seperator-line" />
  <FloatLabel variant="on">
    <AutoComplete
      ref="chipsRef"
      v-model="registeredByComputed"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('purchase.search.filter-registered-by').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-product-numbers"
      data-testid="registered-by-filter"
      input-id="registered-by-filter"
      multiple
      fluid
      :typeahead="false"
    />
    <label for="registered-by-filter">
      {{ t("purchase.search.filter-registered-by") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();
const chipsRef = ref();

const props = defineProps<{
  filters: PurchaseOrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: PurchaseOrderSearchFilters): void;
}>();

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(PurchaseOrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<PurchaseOrderStatus[]>({
  get: () => props.filters.purchaseOrderStatuses,
  set: (value) => {
    const filters = { ...props.filters, purchaseOrderStatuses: value };
    emit("update:filters", filters);
  },
});

const dateFormatted = (value: string): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();
  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;
  return yyyy + "-" + mm + "-" + dd;
};

const fromDateComputed = computed<string>({
  get: () => {
    if (props.filters.fromDate) {
      return dateFormatted(props.filters.fromDate);
    }
    return "";
  },
  set: (value) => {
    let fromDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.fromDate === null) return;
    } else {
      fromDate = new Date(value).toJSON();
      if (props.filters.fromDate === fromDate) return;
    }

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    if (props.filters.toDate) {
      return dateFormatted(props.filters.toDate);
    }
    return "";
  },
  set: (value) => {
    let toDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.toDate === null) return;
    } else {
      toDate = new Date(value).toJSON();
      if (props.filters.toDate === toDate) return;
    }

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const setDateFilterLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);

  const filters = { ...props.filters, toDate: toDate.toJSON(), fromDate: fromDate.toJSON() };
  emit("update:filters", filters);
};

const supplierNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierNumbers: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const registeredByComputed = computed<string[]>({
  get: () => props.filters.registeredBy,
  set: (value) => {
    const filters = { ...props.filters, registeredBy: value };
    emit("update:filters", filters);
  },
});
</script>

<style scoped lang="scss">
.c-seperator-line {
  border: 1px solid var(--seperator-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.dark .c-seperator-line {
  border: 1px solid var(--p-surface-400);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.centered-child {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
