<template>
  <div class="mt-2">
    <FloatLabel variant="on">
      <InputText
        id="contact-email"
        v-model="purchaseOrderReferenceComputed"
        :select-all-on-focus="true"
        data-testId="contact-email"
        :class="{ 'p-disabled': !allowEdit }"
        :disabled="!allowEdit"
        :fluid="true"
        :v-tooltip="{
          value: t('placeholder.type', { property: t('purchase.contact.email-label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="contact-email">{{ t("purchase.contact.email-label") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  email: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const purchaseOrderReferenceComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});
const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>
