<template>
  <FloatLabel variant="on">
    <Select
      id="freight-method"
      v-model="selectedFreightMethod"
      data-testId="freight-method"
      :options="dropdownOptions"
      option-label="name"
      option-value="value"
      :class="{ 'p-invalid': val.freightMethod.$error, 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      :is-required="true"
      class="!mt-1.5 mr-4"
      :fluid="true"
    />
    <label for="freight-method" class="c-required">{{ t("purchase.freight-method") }}</label>
  </FloatLabel>

  <small v-if="val.freightMethod.$error" class="ml-6 p-error" data-testid="freight-method-error">
    {{ val.freightMethod.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { type FreightMethod } from "@/models/freight-method/FreightMethod";
import { useFreightMethod } from "@/api/freight-method/FreightMethodService";
import { PurchaseOrderFreightMethod } from "@/models/purchase-order/PurchaseOrderFreightMethod";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();
const freightMethods = ref<FreightMethod[]>([]);
const { getFreightMethodsForSuppliers } = useFreightMethod();

const props = defineProps<{
  freightMethod: PurchaseOrderFreightMethod;
  defaultFreightMethodId: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:freightMethod", value: PurchaseOrderFreightMethod): void;
}>();

const dropdownOptions = computed(() => {
  return freightMethods.value.map((freightMethod) => {
    return { name: freightMethod.name, value: freightMethod.id };
  });
});

const selectedFreightMethod = computed<string>({
  get: () => {
    return freightMethods.value.find((fm) => fm.id === props.freightMethod?.id)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const fm = freightMethods.value.find((fm) => fm.id === id);
    if (fm && allowEdit.value === true) {
      emit("update:freightMethod", { id: fm.id, name: fm.name });
    }
  },
});

watch(
  () => props.defaultFreightMethodId,
  (id: string) => (selectedFreightMethod.value = id),
);

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});

const fetchFreightMethods = async () => {
  freightMethods.value = await getFreightMethodsForSuppliers();
};
onMounted(fetchFreightMethods);

const rules = {
  freightMethod: {
    name: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);
</script>
