<template>
  <DataTable
    ref="tableProductsSearchResult"
    v-model:expanded-rows="expandedRows"
    :value="searchProducts"
    striped-rows
    data-key="id"
    class="c-datatable c-product-search-result h-auto p-1"
    :class="{
      hidden: !props.showSearchResult,
    }"
    responsive-layout="scroll"
    selection-mode="single"
    :loading="props.loading"
    :row-hover="true"
    data-testid="purchase-product-search-result"
    :pt="{
      bodyrow: ({ context }: DataTablePassThroughMethodOptions) => ({ 'data-id': searchProducts[context.index].id }),
    }"
    @row-expand="onRowExpand"
    @row-select="onRowSelected"
    @row-dblclick="onDblClickProduct"
    @keydown="handleKeyPress($event)"
  >
    <Column expander style="width: 5rem" />

    <Column
      field="productNumber"
      :header="t('purchase.product.number')"
      :pt="{ bodyCell: { 'data-testid': 'product-number' } }"
    />

    <Column field="name" :header="t('purchase.product.name')" :pt="{ bodyCell: { 'data-testid': 'name' } }" />

    <Column
      :header="t('purchase.product.available-stock')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'available-stock' } }"
    >
      <template #body="{ data }">
        {{ n(data.quantityAvailableFromStockForWarehouse) }}
      </template>
    </Column>

    <Column
      :header="t('purchase.product.inventory')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'inventory' } }"
    >
      <template #body="{ data }">
        {{ n(data.quantityInStockForWarehouse) }}
      </template>
    </Column>

    <Column
      :header="t('purchase.product.order-reserve')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'order-reserve' } }"
    >
      <template #body="{ data }">
        {{ data.quantityInOrderReserveForWarehouse ? n(data.quantityInOrderReserveForWarehouse) : "0" }}
      </template>
    </Column>

    <Column
      :header="t('purchase.open-purchase-orders')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'purchase-orders' } }"
    >
      <template #body="{ data }">
        {{ n(data.sumOfQuantityInOpenPurchaseOrders, "decimal") }}
      </template>
    </Column>

    <Column
      :header="t('purchase.product.available')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'available' } }"
    >
      <template #body="{ data }">
        {{ data.quantityAvailable ? n(data.quantityAvailable, "decimal") : "0.00" }}
      </template>
    </Column>

    <Column
      :header="t('purchase.product.in-demand')"
      class="text-right"
      :pt="{ bodyCell: { 'data-testid': 'in-demand' } }"
    >
      <template #body="{ data }">
        {{ n(data.quantityInDemand, "decimal") }}
      </template>
    </Column>

    <Column
      class="c-col-quantity text-center py-0"
      field="quantity"
      :header="t('purchase.product.quantity')"
      style="min-width: 5rem; padding: 0"
    >
      <template #body="{ data, field, index }">
        <PurchaseOrderLineInput
          v-if="editingRowIndex === index && !isNaN(data[field as keyof typeof data])"
          :id="`product-search-quantity-${index}`"
          :disabled="allowAddProducts"
          input-class="c-row-input w-16"
          :value="data.quantity < 1 ? data.quantity++ : data.quantity"
          :min-value="1"
          :allow-empty="false"
          :set-focus="true"
          data-testid="product-search-quantity"
          @on-update-value="data.quantity = $event"
          @keydown.enter.prevent="createNewOrderLine(data, index)"
          @keydown.esc.stop="stopEditRow(index)"
        />
      </template>
    </Column>

    <Column field="supplierPurchasePrice" :header="t('purchase.product.supplier-cost-price')" class="py-0">
      <template #body="{ data, field, index }">
        <PurchaseOrderLineInput
          v-if="
            editingRowIndex === index && !isNaN(data[field as keyof typeof data]) && data.supplierCurrencyIso !== ''
          "
          :id="`product-search-cost-price-${index}`"
          data-testid="product-search-cost-price"
          :value="data[field as keyof typeof data]"
          :currency-iso="data.supplierCurrencyIso"
          :min-fraction-digits="2"
          :max-fraction-digits="2"
          input-class="w-32"
          :min="0"
          @on-update-value="data.supplierPurchasePrice = $event"
          @keydown.enter.prevent="createNewOrderLine(data, index)"
          @keydown.esc.stop="stopEditRow(index)"
        />

        <span v-if="editingRowIndex !== index" :data-testid="`product-search-cost-price-${index}`" data>
          {{ n(data.supplierPurchasePrice, "currency", data.supplierCurrencyIso) }}
        </span>
      </template>
    </Column>

    <Column field="shippingDate" :header="t('purchase.product.shipping-date')" class="max-w-40 py-0">
      <template #body="{ data, field, index }">
        <PurchaseDate
          v-if="editingRowIndex === index"
          :id="`shipping-id-${index}`"
          v-model:date-time="data[field as keyof typeof data]"
          data-test-id="product-search-shipping-date"
          @update:date-time="data.shippingDate = new Date($event).toDateOnlyString()"
          @keydown.enter.prevent="createNewOrderLine(data, index)"
          @keydown.esc.stop="stopEditRow(index)"
        />

        <span v-if="editingRowIndex !== index" :data-testid="`product-search-shipping-date-${index}`" data>{{
          data[field as keyof typeof data]
        }}</span>
      </template>
    </Column>

    <Column field="estimatedArrivalDate" :header="t('purchase.product.eta-date')" class="max-w-40 py-0">
      <template #body="{ data, field, index }">
        <PurchaseDate
          v-if="editingRowIndex === index"
          :id="`arrival-date-${index}`"
          v-model:date-time="data[field as keyof typeof data]"
          data-test-id="product-search-estimated-arrival-date"
          @update:date-time="data.estimatedArrivalDate = new Date($event).toDateOnlyString()"
          @keydown.enter.prevent="createNewOrderLine(data, index)"
          @keydown.esc.stop="stopEditRow(index)"
        />

        <span v-if="editingRowIndex !== index" :data-testid="`product-search-arrival-date-${index}`" data>{{
          data[field as keyof typeof data]
        }}</span>
      </template>
    </Column>

    <Column class="text-right py-0">
      <template #body="{ data, index }">
        <div class="w-12">
          <div v-if="editingRowIndex !== index" class="c-product-search-select">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-plain p-button-text"
              tabindex="-1"
              @click="
                onDblClickProduct({
                  originalEvent: $event,
                  data: data,
                  index: index,
                } as DataTableRowDoubleClickEvent)
              "
            />
          </div>

          <div v-else class="c-product-search-edit">
            <Button
              class="py-1 px-2"
              text
              type="button"
              data-testid="product-search-add"
              @click="createNewOrderLine(data, index)"
            >
              <span class="c-row-add material-symbols-outlined material-filled"> add_circle </span>
            </Button>
          </div>

          <div v-if="editingRowIndex !== index && isUsed(data, props.purchaseOrderLines)">
            <span class="c-product-search-used material-symbols-outlined material-filled"> check_circle </span>
          </div>
        </div>
      </template>
    </Column>

    <template #expansion="">
      <div class="p-4">
        <ProductInfoExtended
          v-if="selectedProduct.id != ''"
          :selected-product="selectedProduct"
          :currencies="currencies"
        />
      </div>
    </template>

    <template #empty>
      {{ t("purchase.no-products-found") }}
    </template>
  </DataTable>
</template>
<script setup lang="ts">
import ProductInfoExtended from "./ProductInfoExtended.vue";
import { useI18n } from "vue-i18n";
import type {
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableRowExpandEvent,
  DataTablePassThroughMethodOptions,
  DataTableExpandedRows,
} from "primevue/datatable";
import { ref } from "vue";
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { Currency } from "@/models/currency/Currency";
import PurchaseOrderLineInput from "./PurchaseOrderLineInput.vue";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import PurchaseDate from "./PurchaseDate.vue";

const { t, n } = useI18n();

const expandedRows = ref();
const tableProductsSearchResult = ref();
const selectedProduct = ref<SearchProductForPurchaseOrder>(new SearchProductForPurchaseOrder());

const props = defineProps<{
  loading: boolean;
  selectedCurrencyIso: string;
  currencies: Currency[];
  purchaseOrderLines: PurchaseOrderLine[];
  selectedSupplierId: string;
  showSearchResult: boolean;
}>();

const searchProducts = defineModel("searchProducts", {
  required: true,
  type: Array<SearchProductForPurchaseOrder>,
});

const onRowExpand = (event: DataTableRowExpandEvent) => {
  selectedProduct.value = event.data as SearchProductForPurchaseOrder;
  expandedRows.value = { [`${selectedProduct.value.id}`]: true } as DataTableExpandedRows;
};

const emit = defineEmits<{
  (e: "createNewOrderLine", value: SearchProductForPurchaseOrder): void;
  (e: "showSupplierDialog", value: SearchProductForPurchaseOrder): void;
}>();

const editingRowIndex = ref<number | null>(null);
const allowAddProducts = ref(false);

const focusFirstRow = () => {
  expandedRows.value = null;
  return tableProductsSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (editingRowIndex.value === event.index) return;

  editingRowIndex.value = null;

  if (event.originalEvent != null) {
    if (!(event.originalEvent instanceof KeyboardEvent)) {
      return;
    }

    const code = (event.originalEvent as KeyboardEvent).code;

    if (code === "Enter") {
      expandedRows.value = null;

      if (props.selectedSupplierId === "") {
        event.originalEvent?.stopPropagation();
        emit("showSupplierDialog", searchProducts.value[event.index]);
        return;
      }

      editingRowIndex.value = event.index;
    }
    if (code === "Space") {
      selectedProduct.value = event.data as SearchProductForPurchaseOrder;
      if (expandedRows.value != undefined && expandedRows.value[selectedProduct.value.id] === true) {
        expandedRows.value = null;
        return;
      }
      expandedRows.value = { [`${selectedProduct.value.id}`]: true } as DataTableExpandedRows;
    }
  }
};

const onDblClickProduct = (event: DataTableRowDoubleClickEvent) => {
  editingRowIndex.value = event.index;

  if (props.selectedSupplierId === "") {
    event.originalEvent?.stopPropagation();
    emit("showSupplierDialog", searchProducts.value[event.index]);
    return;
  }
};

const createNewOrderLine = async (product: SearchProductForPurchaseOrder, index: number = -1) => {
  stopEditRow(index);

  emit("createNewOrderLine", product);
};

const stopEditRow = (index: number) => {
  expandedRows.value = null;
  editingRowIndex.value = null;

  if (tableProductsSearchResult.value) {
    tableProductsSearchResult.value.$el.querySelectorAll("tbody tr")[index]?.focus();
  }
};

const isUsed = (data: SearchProductForPurchaseOrder, purchaseOrderLines: PurchaseOrderLine[]) => {
  const index = purchaseOrderLines.findIndex((ol) => ol.product.id === data.id);
  return index > -1;
};

const selectProduct = (product: SearchProductForPurchaseOrder) => {
  if (tableProductsSearchResult.value) {
    const index = searchProducts.value.findIndex((x) => x.id === product.id);

    if (index > -1) {
      editingRowIndex.value = index;
      tableProductsSearchResult.value.$el.querySelectorAll("tbody tr")[index].focus();
    }
  }
};

const handleKeyPress = async (event: KeyboardEvent) => {
  const elementProductsSearch = tableProductsSearchResult.value.$el.querySelectorAll("tbody tr");
  const elementProductsSearchInput = tableProductsSearchResult.value.$el.querySelectorAll("td input");

  const elementBtnSave = document.getElementById("btn-save") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");

  if (event.key === "Tab" && event.shiftKey === false) {
    if (elementProductsSearch.length < 0) {
      if (elementBtnSave?.disabled) {
        nextTick(() => elementBtnCancel?.focus());
      } else {
        nextTick(() => elementBtnSave?.focus());
      }
      event.preventDefault();
    } else {
      elementProductsSearchInput.forEach((input: HTMLInputElement, index: number) => {
        input.setAttribute("tabindex", (index + 1).toString());
        input.addEventListener("keydown", (event) => {
          if (event.key === "Tab") {
            event.preventDefault();
            const nextIndex = (index + 1) % elementProductsSearchInput.length;
            elementProductsSearchInput[nextIndex].focus();
          }
        });
      });
    }
  }
};

defineExpose({
  focusFirstRow,
  tableProductsSearchResult,
  selectProduct,
});
</script>

<style lang="scss" scoped>
.c-product-search-result .p-datatable-selectable-row {
  .c-row-used {
    color: var(--success-btn-bg);
  }
  &.p-highlight .c-row-used {
    color: var(--primary-color-text);
  }
}

.c-product-search-result.p-datatable .p-datatable-tbody > tr:focus {
  background-color: var(--list-focus-bg);
  outline: none;
}

.c-product-search-result.p-datatable .p-datatable-tbody > tr {
  .c-product-search-select {
    display: none;
    color: var(--action-btn-bg);
    text-align: right;
  }

  .c-product-search-select-edit {
    display: inline;
    color: var(--action-btn-bg);
    text-align: center;
  }

  &:hover .c-product-search-select,
  &:focus .c-product-search-select {
    display: inline;
    .p-button-icon {
      color: var(--action-btn-bg);
    }
  }

  .c-product-search-used {
    color: var(--success-btn-bg);
    padding: 0.2rem 0.5rem;
  }

  &:hover .c-product-search-used,
  &:focus .c-product-search-used {
    display: none;
  }
}
</style>
